import { defineComponent, watch, ref, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { sidebarStore } from "@/store/sidebarStore";
import { TheHeader } from "@/components/header/TheHeader";
import { Locales } from "@/components/locales";
import "./style.scss";
import { routerStore } from "@/store/routerStore";

export interface IActiveComponent {
  index: number;
  hide: boolean;
}
const TheContent = defineComponent({
  name: "Content",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const classTitle = ref("content__title");
    const title = ref<any>(route.meta.name);

    const isMobile = ref(window.innerWidth < 450);

    const downloadButton = () => {
      if (route.meta.download) {
        if (title.value === "Android") {
          return (
            <a href={routerStore.state.link_app} target="__blank">
              <img
                class="content__download-app"
                src={require(`@/assets/images/button-app/android.${isSafari ? 'png': 'webp'}`)}
              />
            </a>
          );
        }
        else if (title.value === "iOS") {
          return (
            <a href={routerStore.state.link_app} target="__blank">
              <img
                class="content__download-app"
                src={require(`@/assets/images/button-app/ios.${isSafari ? 'png': 'webp'}`)}
              />
            </a>
          );
        }
        else if (title.value === "AppGallery") {
          return (
            <a href={routerStore.state.link_app} target="__blank">
              <img
                class="content__download-app"
                src={require(`@/assets/images/button-app/appgallery.${isSafari ? 'png': 'webp'}`)}
              />
            </a>
          );
        }
      }
      else {
        return "";
      }
    };

    title.value = title.value.replace(/{.+}/g, function (text: string) {
      return route.params[text.replace(/{|}/g, "")];
    });

    onMounted(() => {
      window.onresize = () => {
        isMobile.value = window.innerWidth < 820;
      };
    });

    watch(
      () => router.currentRoute.value.meta.name,
      (name: any, _) => {
        classTitle.value = "content__title content__title--change";
        setTimeout(() => {
          title.value = name.replace(/{.+}/g, function (text: string) {
            return route.params[text.replace(/{|}/g, "")];
          });
          classTitle.value = "content__title";
        }, 500);
      }
    );

    return () => (
      <div class={"content"}>
        <div class={classTitle.value}>
          {!isMobile.value && <TheHeader />}
          <div style="width: 100%; display: flex;">
            <div class="content__title-value">{title.value}</div>
            <div class="content__title-button">{downloadButton()}</div>
          </div>
          {isMobile.value && <Locales mobile />}
          <div
            onClick={() => {
              sidebarStore.state.active = !sidebarStore.state.active;
            }}
            class={
              sidebarStore.state.active
                ? "content-nav--burger content-nav--close"
                : "content-nav--burger"
            }
          ></div>
        </div>
        <router-view name="content" />
      </div>
    );
  },
});

export { TheContent };
