import { defineComponent, computed } from "vue"
import { profileStore } from "@/store/profileStore";
import './style.scss'

const UnderProtect = defineComponent({
  name: "UnderProtect",
  setup() {
    const path = location.pathname
    if (['/auth', '/profile/confirmation'].indexOf(path) === -1) {
      profileStore.getProtectionStatus()
    }
    const protection_status = computed(() => {
      if (profileStore.state.protection_status) {
        return profileStore.state.protection_status
      }
      return false
    })
    const close = () => {
      profileStore.state.protection_status = false
    }
    return () => (
      protection_status.value && <div class={'under-protect'}>
        <span class={"under-protect__label"}>{window.translate.protected_dubkivpn}</span>
        <div class={"under-protect__close"} onClick={close}>&#10005;</div>
      </div>
    );
  },
});

export { UnderProtect };
