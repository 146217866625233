import { defineComponent, ref, PropType, computed } from "vue";
import "./style.scss"

const RangePicker = defineComponent({
  name: "RangePicker",
  props: {
    label: {
      type: String,
      required: true as true,
    },
    min: {
      type: Number,
      required: true as true,
    },
    value: {
      type: Number,
    },
    max: {
      type: Number,
      required: true as true,
    },
    onChange: Function as PropType<(value: number) => void>,
  },
  setup(props, { emit }) {
    emit("change", props.value)

    // изменение ползунка
    const inputChange = (e: any) => {
      emit("change", parseInt(e.target.value))
    }
    // изменение значения поля
    const inputValueChange = (e: any) => {
      const value = e.target.value
      if (value === "" || value < props.min) {
        emit("change", props.min)
      } else {
        emit("change", parseInt(e.target.value))
      }
    }
    // проверка на число
    const isNumber = (char: any) => {
      const converChar = char.toString()
      const regx = new RegExp(/[0-9]{1}$/g)
      return regx.test(converChar)
    }
    // вводим только цифры
    const onlyNumbers = (payload: KeyboardEvent) => {
      const value = props.value
      if (isNumber(payload.key)) {
         // проверка на ноль
         if (value === 0 && payload.key === '0') {
           emit("change", 0)
           return payload.preventDefault()
         }
         // проверка больше ли будет значение поля
         if(parseInt(value + payload.key) > props.max) {
          emit("change", props.max)
          return payload.preventDefault()
        }
        return true
      }
      return payload.preventDefault()
    }
    return () => (
      <div class="range-slider">
        <h1 class="range-slider__title">{ props.label }</h1>
        <div class="range-slider__text-box">
          <input disabled={props.max > 0 ? false : true} type="text" onKeypress={(payload: KeyboardEvent) => onlyNumbers(payload)} value={props.value} onInput={(e) => inputValueChange(e)} class="range-slider__value" />
        </div>
        <div class="range-slider__slider">
          <input
            type="range"
            min={props.min}
            max={props.max}
            value={props.value}
            class="slider"
            onInput={(e) => inputChange(e)}
          />
        </div>
      </div>
    );
  },
});

export { RangePicker };
