import { defineComponent, ref, PropType } from "vue"
import "./style.scss"

const BaseInput = defineComponent({
  name: "BaseInput",
  props: {
    placeholder: {
      type: String,
    },
    error: {
      type: Array as PropType<string[]>,
      required: true as true
    },
    disable: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true as true
    },
    mode: {
      type: String as PropType<"text" | "none" | "tel" | "url" | "email" | "numeric" | "decimal" | "search">,
      required: true as true,
    },
    onValue: Function as PropType<(value: string) => void>,
    onClick: Function as PropType<(value: string) => void>,
  },
  setup(props, { emit, slots }) {
    const value = ref()
    const inputted = (event: any) => {
      value.value = event.target.value;
      emit("value", value.value)
    };
    const inputKeyPress = (keyboardEvent: any) => {
      const key = keyboardEvent.key
      if (key === "Enter") {
        emit("click")
      }
    }
    return () => (
      <div class={!!value.value ? props.error.length ? "base-input base-input--active base-input--error" :"base-input base-input--active" : props.error.length ? "base-input--error base-input" : "base-input"}>
        <div class="base-input__groop">
          <input onKeypress={inputKeyPress} name={props.type} autocomplete={props.type} disabled={props.disable} onInput={inputted} class="base-input__input" type={props.type} />
          <div class={"base-input__placeholder"}>{props.placeholder}</div>
          <div class="base-input__icon">{slots.default!()}</div>
        </div>
        {props.error.map((error) => <div class="base-input__error">{error}</div>)}
      </div>
    )
  }
})

export { BaseInput }
