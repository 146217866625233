import { defineComponent, ref } from "vue";
import { localesStore } from "@/store/localesStore";
import Cookies from "js-cookie";
import "./style.scss";

const Locales = defineComponent({
  name: "Locales",
  props: {
    mobile: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const locales = window.locales;
    const locales_element = ref();
    const current_locale = Cookies.get('dvpn_auth_locale');
    let currentLocale = ref(current_locale || "ru");

    // @ts-ignore
    const setLocalization = (code, run) => {
      if (!run) {
        currentLocale.value = code;
        if (locales_element.value) {
          locales_element.value.blur();
        }
        localesStore.setLocale(code).then(() => {
      	  const domain = process.env.VUE_APP_MODE === '0' ? 'domain.com' : process.env.VUE_APP_MODE === '1' ? 'dl-net.ru ' : 'dubki-vpn.ru';
          Cookies.set('dvpn_auth_locale',code, { path: '/', domain: domain, expires: 365 })
          // window.location.reload();
        });
      }
    };

    return () => (
      <div tabindex="0" class="locale__wrapper noselect" ref={locales_element}>
        <div class={props.mobile ? "locale__current locale__current--mobile" : "locale__current"}>{currentLocale.value}</div>
        <div class="locale__triangle locale__box--hidden"></div>
        <div class="locale__box locale__box--hidden">
          {locales.map((locale: string) => (
            <div
              key="'locale' + locale"
              class={
                currentLocale.value === locale ? "locale locale--hidden" : "locale"
              }
              onClick={() => setLocalization(locale, currentLocale.value === locale)}
            >
              {locale}
            </div>
          ))}
        </div>
      </div>
    );
  },
});

export { Locales };
