import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { BaseCard } from "@/components/base/card/BaseCard/BaseCard";
import "./style.scss";

const HelpCard = defineComponent({
  name: "HelpCard",
  setup() {
    const router = useRouter()
    const clientWidth = ref(document.documentElement.clientWidth)
    const isNotMobile = ref(clientWidth.value > 440)
    const isHelp = ref(false)
    const to = (path: string) => {
      router.push(path)
    }
    onMounted(() => {
      window.addEventListener('resize', () => {
        clientWidth.value = document.documentElement.clientWidth
        isNotMobile.value = clientWidth.value > 440
      });
    })
    return () => (
      <BaseCard style={isNotMobile.value ? "width: 283px;" : ""}>
        <div class={"help-card"}>
          {!isHelp.value && <div class="help-card__question--1" onClick={() => {isHelp.value = true}}>{window.translate.how_to_connect}</div>}
          {isHelp.value && <div>
            <div class="help-card__question--2">{window.translate.what_is_your_os}</div>
            <div class="help-card__answers">
              <div class="help-card__answer" onClick={() => to('/connection/windows7')}>Windows 7</div>
              <div class="help-card__answer" onClick={() => to('/connection/windows10')}>Windows 10</div>
              <div class="help-card__answer" onClick={() => to('/connection/macos')}>macOS</div>
              <div class="help-card__answer" onClick={() => to('/connection/ios')}>iOS</div>
              <div class="help-card__answer" onClick={() => to('/connection/android')}>Android</div>
            </div>
          </div>}
        </div>
      </BaseCard>
    );
  },
});

export { HelpCard };
