import { defineComponent, ref, watch, computed } from "vue";
import { BaseCard } from "@/components/base/card/BaseCard/BaseCard";
import { NumberPicker } from "@/components/numberPicker/NumberPicker";
import { ThePayment } from "@/components/payment/ThePayment";
import { TextPicker } from "@/components/textPicker/TextPicker";
import { RangePicker } from "@/components/rangePicker/RangePicker";
import { subscriptionsStore } from "@/store/subscriptionsStore";
import { profileStore } from "@/store/profileStore";
import { serversStore } from "@/store/serversStore";
import { notificationStore } from "@/store/notificationStore";
import { payStore } from "@/store/payStore";
import { BaseLoader } from "@/components/base/loaders/BaseLoader";
import "./style.scss";

const TariffCard = defineComponent({
  name: "TariffCard",
  setup() {
    // Получение баланса
    profileStore.getBalance();
    const isButtonDisable = ref(false);
    const hideJivoStyle = ref();
    const selectRealMoney = ref<number>(0);
    const selectBonusMoney = ref<number>(0);
    const maxRealMoney = ref<number>(0);
    const maxBonusMoney = ref<number>(0);
    const isChange = ref(false);
    const isLoading = ref(false);
    const accessLevel = ref(1);
    const accessLevelOld = ref(0);
    const price = ref(0);
    const currentPrice = ref(0);
    const oldPrice = ref(0);
    const active_until = ref("");
    const bill = ref(0);
    const timerId = ref<number>();
    const countMount = ref(1);
    const countMountOld = ref(0);
    const active_tariff = {
      price: 0,
      active_until: "",
      level: 1,
      month: 1,
      version: "",
    };
    const date = ref("");
    const serverText = ref("");

    const isNewTariff =
      countMount.value !== active_tariff.month ||
      accessLevel.value !== active_tariff.level;

    const convertDate = (date: string) => {
      const newDate: string[] = date.split(" ")[0].split("-");
      return `${newDate[2]}.${newDate[1]}.${newDate[0]}`;
    };

    const tariff_version = () => {
      if (
        !serversStore.state.servers.length ||
        !subscriptionsStore.state.subscriptions.length ||
        active_tariff.version === "v2" ||
        countMount.value !== active_tariff.month ||
        accessLevel.value !== active_tariff.level
      ) {
        return "_v2";
      } else {
        return "";
      }
    };

    // Баланс
    const real_money = computed(() => {
      if (profileStore.state.balance?.real_money) {
        return profileStore.state.balance.real_money;
      }
      return 0;
    });

    // Бонусный баланс
    const bonus_money = computed(() => {
      if (profileStore.state.balance?.bonus_money) {
        return profileStore.state.balance.bonus_money;
      }
      return 0;
    });

    const raf = ref();

    const loop = (value: number, step: number) => {
      if (!isChange.value || price.value === value) {
        cancelAnimationFrame(raf.value);
      } else {
        if (step < 0) {
          if (price.value + step > value) {
            price.value += step;
          } else {
            price.value = value;
          }
        }
        if (step > 0) {
          if (price.value + step < value) {
            price.value += step;
          } else {
            price.value = value;
          }
        }

        raf.value = requestAnimationFrame(() => {
          loop(value, step);
        });
      }
    };

    const priceAnimate = (value: number) => {
      const temp = 30;
      const step = ref(0);
      // повышаем тариф
      if (value > price.value) {
        step.value = Math.ceil((value - price.value) / temp);
      }
      // понижаем тариф
      else if (value < price.value) {
        step.value = -1 * Math.ceil((price.value - value) / temp);
      }

      raf.value = requestAnimationFrame(() => {
        loop(value, step.value);
      });
    };

    const getPrice = () => {
      const tariff_plan =
        countMount.value === 0 && accessLevel.value === 0
          ? "trial"
          : `m_${countMount.value}_g_${accessLevel.value}${tariff_version()}`;
      subscriptionsStore
        .postSubscriptions(tariff_plan, "0", "0", "preview")
        .then(() => {
          if (subscriptionsStore.state.price) {
            currentPrice.value = subscriptionsStore.state.price;

            maxRealMoney.value =
              real_money.value >= currentPrice.value
                ? currentPrice.value
                : real_money.value;

            maxBonusMoney.value =
              bonus_money.value >= currentPrice.value
                ? currentPrice.value
                : bonus_money.value;

            selectRealMoney.value = maxRealMoney.value;
            selectBonusMoney.value = maxRealMoney.value
              ? 0
              : maxBonusMoney.value;
            priceAnimate(subscriptionsStore.state.price);
          } else {
            priceAnimate(0);
          }
        });
    };

    const closeTariff = () => {
      clearInterval(timerId.value);
      price.value = oldPrice.value;
      accessLevel.value = accessLevelOld.value;
      bill.value = 0;
      isChange.value = false;
      if (hideJivoStyle.value) {
        hideJivoStyle.value.remove();
      }
    };

    const products = ref([
      {
        name: "Trial",
        price: 0,
        quantity: 1,
        variant: window.translate.month_1,
      },
    ]);

    // списание с баланса
    const payRealMoney = ref(0);

    // списание с бонусного баланса
    const payBonusMoney = ref(0);

    const commerce = (
      accessLevel: number,
      countMount: number,
      method: string
    ) => {
      const price = ref(0);
      const ecommerce = ref();
      switch (accessLevel) {
        case 1:
          price.value = isNewTariff ? 199 : 99;
          break;
        case 2:
          price.value = isNewTariff ? 499 : 299;
          break;
        case 3:
          price.value = isNewTariff ? 799 : 499;
          break;
        default:
          price.value = 0;
          break;
      }
      price.value =
        countMount === 12 ? parseInt(`${price.value}9`) : price.value;
      const name = `${price.value} ${window.translate.rub}/${
        countMount === 12 ? window.translate.year : window.translate.month
      }`;
      const variant =
        countMount === 1
          ? `1 ${window.translate.month}`
          : `12 ${window.translate.months}`;
      products.value = [
        {
          name: name,
          price: price.value,
          quantity: 1,
          variant: variant,
        },
      ];
      if (method === "view") {
        ecommerce.value = {
          ecommerce: {
            currencyCode: window.translate.rub,
            detail: {
              products: products.value,
            },
          },
        };
      }
      if (method === "cart") {
        localStorage.setItem("cart_name", name);
        localStorage.setItem("cart_price", price.value.toString());
        localStorage.setItem("cart_variant", variant);
        ecommerce.value = {
          ecommerce: {
            currencyCode: window.translate.rub,
            add: {
              products: products.value,
            },
          },
        };
      }
      if (method === "pay") {
        const name = localStorage.getItem("cart_name");
        const price = localStorage.getItem("cart_price");
        const variant = localStorage.getItem("cart_variant");
        const payment_id = localStorage.getItem("payment_id");

        window.dataLayer.push({
          ecommerce: {
            currencyCode: window.translate.rub,
            purchase: {
              actionField: {
                id: payment_id,
              },
              products: [
                {
                  name: name,
                  price: price,
                  variant: variant,
                  quantity: 1,
                },
              ],
            },
          },
        });
      }
      window.dataLayer.push(ecommerce.value);
    };

    const getServerText = () => {
      // if status === 'expired' || '' ---> Доступ к серверам ограничен
      // if tariffPlan.alias === 'trial' ---> Тариф предоставляет доступ к пробному серверу
      // if accessLevel === 1,2,3,4  ---->

      if (
        !isChange.value &&
        (subscriptionsStore.state.active_subscription.status === "expired" ||
          subscriptionsStore.state.active_subscription.status === "")
      ) {
        return window.translate.access_servers_limited;
      } else if (
        !isChange.value &&
        subscriptionsStore.state.active_subscription?.tariff_plan?.alias ===
          "trial"
      ) {
        return window.translate.tariff_provides_access_trial;
      } else if (isChange.value || accessLevel.value >= 1) {
        const server_count =
          accessLevel.value === 1
            ? accessLevel.value
            : accessLevel.value === 2
            ? 3
            : 5;
        return (
          window.translate.tariff_provides_access +
          " " +
          `<span><strong>${server_count}</strong><span> ${
            accessLevel.value === 1
              ? window.translate.server
              : window.translate.servers
          }</span></span>`
        );
      } else {
        return "";
      }
    };
    serverText.value = getServerText();

    const getDate = () => {
      const messageWarn = (message: string) =>
        `<span style="color: red;">${message}</span>`;

      const status = subscriptionsStore.state?.active_subscription?.status;
      const tariffPlan =
        subscriptionsStore.state?.active_subscription?.tariff_plan;

      if (!tariffPlan) return messageWarn(window.translate.no_active_rate); // нет активного тарифа
      if (status === "expired") return messageWarn(window.translate.expired); // истёк
      if (status !== "active")
        return messageWarn(window.translate.no_active_rate); // нет активного тарифа
      if (tariffPlan?.alias === "trial") return window.translate.trial; // пробная версия

      return `${window.translate.before} ${convertDate(active_until.value)}`; // до 31.08.2024
    };

    date.value = getDate();

    // Получение тарифного плана
    const getTariffPlan = () => {
      if (subscriptionsStore.state.active_subscription?.id) {
        const alias: string[] =
          subscriptionsStore.state.active_subscription?.tariff_plan?.alias.split(
            "_"
          );
        // сохранение активного тарифа
        active_tariff.price =
          subscriptionsStore.state.active_subscription?.tariff_plan?.price || 0;
        active_tariff.active_until =
          subscriptionsStore.state.active_subscription?.active_until;
        active_tariff.level = alias[0] !== "trial" ? parseInt(alias[3]) : 1;
        active_tariff.month = alias[0] !== "trial" ? parseInt(alias[1]) : 1;
        active_tariff.version = alias[0] !== "trial" ? alias[4] : "v2";
        price.value = active_tariff.price;

        priceAnimate(
          subscriptionsStore.state.active_subscription?.tariff_plan?.price || 0
        );
        active_until.value =
          subscriptionsStore.state.active_subscription?.active_until;
        if (alias) {
          if (alias[0] === "") {
            accessLevel.value = 1;
            countMount.value = 1;
          } else {
            accessLevel.value = alias[0] !== "trial" ? parseInt(alias[3]) : 1;
            countMount.value = alias[0] !== "trial" ? parseInt(alias[1]) : 1;
          }
        } else {
          accessLevel.value = 1;
          countMount.value = 1;
        }
        accessLevelOld.value = accessLevel.value;
        countMountOld.value = countMount.value;
        date.value = getDate();
        serverText.value = getServerText();
      }
    };
    getTariffPlan();

    watch([accessLevel, countMount], () => {
      if (isChange.value) {
        commerce(accessLevel.value, countMount.value, "view");
        getPrice();
      }
    });

    const changeTariff = (element: MouseEvent, status?: string) => {
      if (!isChange.value) {
        oldPrice.value = price.value;
        isChange.value = true;
        commerce(accessLevel.value, countMount.value, "view");
        getPrice();
        hideJivoStyle.value = document.createElement("style");
        hideJivoStyle.value.id = "hide-jivo";
        hideJivoStyle.value.innerHTML = "jdiv { display: none !important; }";
        document.body.append(hideJivoStyle.value);
      } else {
        if (isButtonDisable.value) {
          return;
        }
        isButtonDisable.value = true;
        if (status === window.translate.renew_2) {
          window.ym(45609672, "reachGoal", "click_on_the_extend_button");
        }
        commerce(accessLevel.value, countMount.value, "cart");

        const tariff_plan =
          countMount.value === 0 && accessLevel.value === 0
            ? "trial"
            : `m_${countMount.value}_g_${accessLevel.value}${tariff_version()}`;

        isLoading.value = true;
        subscriptionsStore
          .postSubscriptions(
            tariff_plan,
            payBonusMoney.value.toString(),
            payRealMoney.value.toString(),
            "bill",
            element
          )
          .then((response) => {
            if (response?.data.bill_ct) {
              localStorage.setItem("payment_id", response.data.bill_ct || "");
            }
            if (response?.data.bill_id) {
              localStorage.setItem(
                "payment_id",
                response?.data.bill_id.toString() || ""
              );
            }
            // если оплачено то выводим сообщение о успешной оплате
            if (response?.data?.bill_status === "paid") {
              subscriptionsStore.getSubscriptions().then(() => {
                bill.value = 0;
                getTariffPlan();
                isChange.value = false;
                profileStore.getBalance(true);
                commerce(accessLevel.value, countMount.value, "pay");
                serversStore.getServers();
                notificationStore.state.notification.message =
                  window.translate.successful_payment;
                notificationStore.state.notification.view = true;
                window.ym(45609672, "reachGoal", " successful_payment");
              });
            }
            // если не хватает средств то выводим модальное окно оплаты
            if (response?.data?.bill_status === "pending") {
              payStore.state.isShowPayment = true;
            }
            isButtonDisable.value = false;
          })
          .catch((e) => {
            const error = e.response.data.data;
            const messages: string[] = [];
            Object.keys(error).map((key) => {
              if (typeof error[key] === "string") {
                messages.push(error[key]);
              } else {
                error[key].map((err: string) => {
                  messages.push(err);
                });
              }
            });
            notificationStore.state.notification.message = messages[0];
            notificationStore.state.notification.view = true;
            isButtonDisable.value = false;
          })
          .finally(() => {
            isLoading.value = false;
          });
      }
    };

    return () => (
      <BaseCard
        onClose={closeTariff}
        class={isChange.value ? "base-card--view" : null}
      >
        {payStore.state.isShowPayment && <ThePayment />}
        <div class={isChange.value ? "tariff-card change" : "tariff-card"}>
          <div class={"label"}>
            <span class={"label-number"}>{price.value}</span>
            <span class={"label-child"}>
              <div>
                {countMount.value === 12
                  ? window.translate.rub_year
                  : window.translate.rub_month}
              </div>
              {!isChange.value && (
                <div style={"font-size: 20px;"} v-html={date.value}></div>
              )}
            </span>
          </div>
          <BaseLoader active={isLoading.value} />
          {!isLoading.value && isChange.value && bill.value === 1 ? (
            <>
              {!!real_money.value && (
                <RangePicker
                  min={0}
                  value={selectRealMoney.value}
                  max={maxRealMoney.value}
                  label={window.translate.balance}
                  onChange={(value: number) => {
                    payRealMoney.value = value;
                    payBonusMoney.value = 0;
                    selectRealMoney.value = value;
                    selectBonusMoney.value = 0;
                  }}
                />
              )}
              {!!bonus_money.value && (
                <RangePicker
                  min={0}
                  value={selectBonusMoney.value}
                  max={maxBonusMoney.value}
                  label={window.translate.bonus_balance}
                  onChange={(value: number) => {
                    payBonusMoney.value = value;
                    payRealMoney.value = 0;
                    selectBonusMoney.value = value;
                    selectRealMoney.value = 0;
                  }}
                />
              )}
              {(!!bonus_money.value || !!real_money.value) && (
                <div style="height: 120px;"></div>
              )}
            </>
          ) : (
            <>
              {!isLoading.value && isChange.value ? (
                <NumberPicker
                  active={accessLevel.value}
                  onChange={(num) => (accessLevel.value = num)}
                  numbers={[1, 2, 3]}
                  title={window.translate.access_level}
                />
              ) : null}
              {!isLoading.value && isChange.value ? (
                <NumberPicker
                  active={countMount.value}
                  onChange={(num) => (countMount.value = num)}
                  numbers={[1, 12]}
                  title={window.translate.number_months}
                />
              ) : null}
            </>
          )}
          {!isLoading.value &&
          isChange.value &&
          bonus_money.value + real_money.value > 0 ? (
            <TextPicker
              active={bill.value}
              texts={[
                window.translate.from_payment_card,
                window.translate.bonus_balance,
              ]}
              title={window.translate.withdrawal_account}
              onChange={(num: number) => {
                bill.value = num;
                payBonusMoney.value = 0;
                payRealMoney.value = 0;
              }}
            />
          ) : null}
          <div class={"description"}>
            <div
              style="font-size: 11px;color: #545454;"
              v-html={serverText.value}
            ></div>
            <div
              class={"cursor-pointer button"}
              onClick={(e) =>
                changeTariff(
                  e,
                  subscriptionsStore.state.active_subscription.status ===
                    "expired"
                    ? window.translate.renew_2
                    : accessLevel.value >= accessLevelOld.value
                    ? window.translate.buy
                    : window.translate.switch
                )
              }
            >
              {subscriptionsStore.state.active_subscription.status === "expired"
                ? window.translate.renew_2
                : accessLevel.value >= accessLevelOld.value
                ? window.translate.buy
                : window.translate.switch}
            </div>
            <div
              class={"cursor-pointer button button--close"}
              onClick={closeTariff}
            >
              {window.translate.close}
            </div>
          </div>
        </div>
      </BaseCard>
    );
  },
});

export { TariffCard };
