import { reactive } from "vue";
import { errorStore } from "./errorStore";
import { profileService } from "@/services/profileService";

export interface IState {
  current_locale: null|string;
  domain: string;
}

const state = reactive<IState>({
  current_locale: null,
  domain: process.env.VUE_APP_MODE === '0' ? 'domain.com' : process.env.VUE_APP_MODE === '1' ? 'dl-net.ru' : 'dubki-vpn.ru',
});

const actions = {
  async setLocale(locale: string) {
    try {
      state.current_locale = locale;
      return await profileService.language(locale);
    } catch (e) {
      errorStore.treatment("setLocale", e);
    }
  },
}

export const localesStore = {
  state,
  ...actions
};
