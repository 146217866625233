import { defineComponent, ref } from "vue";
import "./style.scss";

const Smartbanner = defineComponent({
  name: "Smartbanner",
  props: {
    url: {
      type: String,
      default: "#",
    },
    title: {
      type: String,
      default: "",
    },
    author: {
      type: String,
      default: "",
    },
    price: {
      type: String,
      default: "",
    },
    button: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const isShowSmartbanner = ref(false);
    const temp = localStorage.getItem("smartbaner-show");
    if (temp) {
      isShowSmartbanner.value = false;
    } else {
      const pathname = location.pathname;
      const exclude = ['/auth'];
      var ua = navigator.userAgent.toLowerCase();
      const check = ua.indexOf("android") > -1 && !exclude.includes(pathname);
      isShowSmartbanner.value = check;
    }

    const close = () => {
      isShowSmartbanner.value = false;
      document
        .querySelectorAll(".space--android")
        .forEach((n) => n.classList.remove("space--android"));
      localStorage.setItem("smartbaner-show", "false");
    };

    return () =>
      isShowSmartbanner.value && (
        <div class="smartbanner">
          <div class="smartbanner__close" onClick={close}></div>
          <div class="smartbanner__icon"></div>
          <div class="smartbanner__content">
            <div class="smartbanner__info">
              <div class="smartbanner__title">{props.title}</div>
              <div class="smartbanner__author">{props.author}</div>
              <div class="smartbanner__price">{props.price}</div>
            </div>
            <a href={props.url} target="__blank" class="smartbanner__button">
              {props.button}
            </a>
          </div>
        </div>
      );
  },
});

export { Smartbanner };
