import { defineComponent, PropType } from "vue"
import "./style.scss"

const BaseButton = defineComponent({
  name: "BaseButton",
  props: {
    value: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onClick: Function as PropType<() => void>,
  },
  setup(props, { emit }) {
    const eventClick = () => {
      if (!props.disabled) {
        emit("click")
      }
    }
    return () => (
      <button type="button" onClick={eventClick} class="base-button">{props.value}</button>
    )
  }
})

export { BaseButton }
