import { createApp } from "vue";
import { App, translate } from "./App";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import router from "./router";
import { authStore } from "./store/authStore";
import Cookies from "js-cookie";
import vSelect from "vue-select";

let temp_locale = Cookies.get("dvpn_auth_locale") || "ru";

const watchToken = () => {
  const dynamicToken = Cookies.get("dvpn_auth_token");
  const staticToken = Cookies.get("dvpn_auth_token_static");
  const domain_locale = Cookies.get("dvpn_auth_locale") || "ru";

  if (temp_locale.length && temp_locale !== domain_locale) {
    temp_locale = domain_locale;
    window.location.reload();
  }

  if (dynamicToken && dynamicToken !== staticToken) {
    // перезаходим под динамическим токеном
    if (typeof dynamicToken === "string") {
      authStore.setToken(dynamicToken);
      router.push("/");
    }
  } else if (dynamicToken && !staticToken) {
    // входим с динамическим токеном
    if (typeof dynamicToken === "string") {
      authStore.setToken(dynamicToken);
      router.push("/");
    }
  } else if (!dynamicToken && staticToken) {
    // выходим
    authStore.logout();
  } else if (!dynamicToken && !staticToken && location.pathname !== "/auth") {
    // выходим
    authStore.logout();
  } else if (
    dynamicToken &&
    staticToken &&
    dynamicToken === staticToken &&
    location.pathname === "/auth"
  ) {
    // location.href = "/overview";
  }
  setTimeout(watchToken, 500);
};

watchToken();

const app = createApp(App);
if (process.env.VUE_APP_SENTRY_ENVIRONMENT !== "local") {
  Sentry.init({
    app,
    dsn: `https://5e4d808aa43d481297203276ab24ee3f@${process.env.VUE_APP_SENTRY_DOMAIN}/5`,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    logErrors: process.env.VUE_APP_SENTRY_LOG,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          "dvpn-panel-dl-dev.dev.k8s.dl-net.ru",
          "my.dubki-vpn.ru",
          /^\//,
        ],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

app.provide("translate", translate());
app.use(router);
app.component("v-select", vSelect);
app.mount("#app");
