import { httpClient } from "@/http";

export interface IConnectionResponse {
  data: {
    password: string;
    username: string;
  };
}
export interface IBalanceResponse {
  data: {
    bonus_money: number;
    email: string;
    language: string;
    email_confirmed: boolean;
    real_money: number;
    access_level?: number;
  };
}
export interface IProtectionStatusResponse {
  data: {
    under_protection: boolean;
  };
}
export interface IEmailReconfirmationResponse {
  data: {
    status_code: number;
    status_message: string;
    data: string;
  };
}

export interface ILanguageRequest {
  [key: string]: string;
}

class ProfileService {
  public connection() {
    return httpClient.get<IConnectionResponse>("/profile/connection");
  }
  public emailReconfirmation() {
    return httpClient.post<IEmailReconfirmationResponse>(
      "/profile/email-reconfirmation"
    );
  }
  public language(code: string) {
    return httpClient.patch<ILanguageRequest>("/profile/language", {
      language: code,
    });
  }
  public balance() {
    return httpClient.get<IBalanceResponse>("/profile/balance");
  }
  public online() {
    return httpClient.post("/profile/online", {
      platform: "web",
      version: navigator.userAgent,
    });
  }
  public protection_status() {
    return httpClient.get<IProtectionStatusResponse>(
      "/profile/protection-status"
    );
  }
}

export const profileService = new ProfileService();
