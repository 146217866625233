import { reactive } from 'vue'

export interface IState {
  notification: {
    view: boolean;
    message: string | null;
  };
}
const state = reactive<IState>({
  notification: {
    view: false,
    message: null
  }
})

export const notificationStore = {
  state,
};

