import { httpClient } from '@/http'

export interface IServer {
  id: number;
  ip: string;
  label: string;
  psk: string;
  tariff_level: number;
  type: string;
} 

export interface IResponse {
  data: IServer[];
}

class ServersService {
  public get() {
    return httpClient.get<IResponse>('/servers')
  }
  public getFile(id: number) {
    return httpClient.get<string>(`/servers/${id}/profile`)
  }
}

export const serversService = new ServersService()
