import { reactive } from "vue";
import {
  businessService,
  ICreateData,
  IUpdateData,
} from "@/services/businessServive";

export interface IState {
  clients: any[];
  meta: any;
}

const state = reactive<IState>({
  clients: [],
  meta: null,
});

const actions = {
  clientGet(id: number) {
    return new Promise((resolve, reject) => {
      businessService
        .clientGet(id)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  clientCreate(data: ICreateData) {
    return new Promise((resolve, reject) => {
      businessService
        .clientCreate(data)
        .then((response: any) => {
          if (response?.data?.data) {
            state.clients = response.data.data;
          }
          if (response?.data?.meta) {
            state.meta = response.data.meta;
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  clientUpdate(data: IUpdateData) {
    return new Promise((resolve, reject) => {
      businessService
        .clientUpdate(data)
        .then((response: any) => {
          if (response?.data?.data) {
            state.clients = response.data.data;
          }
          if (response?.data?.meta) {
            state.meta = response.data.meta;
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  clientRemove(id: number | string) {
    return new Promise((resolve, reject) => {
      businessService
        .clientRemove(id)
        .then((response: any) => {
          if (response?.data?.data) {
            state.clients = response.data.data;
          }
          if (response?.data?.meta) {
            state.meta = response.data.meta;
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  clientList() {
    return new Promise((resolve, reject) => {
      businessService
        .clientList()
        .then((response: any) => {
          if (response?.data?.data) {
            state.clients = response.data.data;
          }
          if (response?.data?.meta) {
            state.meta = response.data.meta;
          }
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export const businessStore = {
  state,
  ...actions,
};
