import { IconView } from "@/assets/icons/IconView";
import { IconApps } from "@/assets/icons/IconApps";
import { IconInstructions } from "@/assets/icons/IconInstructions";
import { IconAdmin } from "@/assets/icons/IconAdmin";
// import { IconProfile } from "@/assets/icons/IconProfile";
import { authStore } from "@/store/authStore";
import { sidebarStore } from "@/store/sidebarStore";
import { promocodeStore } from "@/store/promocodeStore";

export interface IElement {
  title: string;
  path: string;
  access_level: number;
  method?: () => void;
}

export interface ISection {
  title: string;
  path: string;
  access_level: number;
  icon?: any;
  method?: () => void;
  elements?: IElement[];
}

const logout = () => authStore.logout();
const isMobile = window.innerWidth < 450;
const closeSidebar = () =>
  isMobile ? (sidebarStore.state.active = false) : null;
const promocode = () => {
  isMobile ? (sidebarStore.state.active = false) : null;
  promocodeStore.state.isPromocode = true;
  promocodeStore.state.error = null;
};

const Sections: ISection[] = [
  {
    title: window.translate.dashboard,
    path: "/overview",
    icon: IconView,
    method: closeSidebar,
    access_level: 1,
    elements: [
      {
        title: window.translate.tariff,
        path: "/tariff",
        access_level: 1,
        method: closeSidebar,
      },
      {
        title: window.translate.cards,
        path: "/cards",
        access_level: 1,
        method: closeSidebar,
      },
      {
        title: window.translate.promocode,
        path: "",
        access_level: 1,
        method: promocode,
      },
      {
        title: window.translate.bonuses,
        path: "/bonuses",
        access_level: 1,
        method: closeSidebar,
      },
    ],
  },
  {
    title: window.translate.instruction,
    path: "",
    icon: IconInstructions,
    access_level: 1,
    elements: [
      {
        title: window.translate.windows_10,
        path: "/connection/windows10",
        access_level: 1,
        method: closeSidebar,
      },
      {
        title: window.translate.windows_7,
        path: "/connection/windows7",
        access_level: 1,
        method: closeSidebar,
      },
      {
        title: window.translate.macos,
        path: "/connection/macos",
        access_level: 1,
        method: closeSidebar,
      },
      {
        title: window.translate.ios,
        path: "/connection/ios",
        access_level: 1,
        method: closeSidebar,
      },
      {
        title: window.translate.android,
        path: "/connection/android",
        access_level: 1,
        method: closeSidebar,
      },
      {
        title: window.translate.openvpn,
        path: "/connection/openvpn",
        access_level: 1,
        method: closeSidebar,
      },
    ],
  },
  {
    title: window.translate.applications,
    path: "",
    icon: IconApps,
    access_level: 1,
    elements: [
      {
        title: window.translate.android,
        path: "/app/android",
        access_level: 1,
        method: closeSidebar,
      },
      {
        title: window.translate.ios,
        path: '/app/ios',
        access_level: 1,
        method: closeSidebar
      },
      {
        title: 'AppGallery',
        path: '/app/app-gallery',
        access_level: 1,
        method: closeSidebar
      }
    ],
  },
  {
    title: "Компании",
    path: "/company/list",
    icon: IconAdmin,
    access_level: 20,
    elements: []
  },
  {
    title: "Управление",
    path: "",
    icon: IconAdmin,
    access_level: 20,
    elements: [
      {
        title: "Пользователи",
        path: "/admin/users",
        access_level: 20,
        method: closeSidebar,
      },
    ],
  },
  // {
  //   title: "Профиль",
  //   path: "/profile",
  //   icon: IconProfile,
  //   access_level: 1,
  // },
];
if (isMobile) {
  Sections.push({
    title: window.translate.logout,
    path: "",
    method: logout,
    access_level: 1,
    elements: [],
  });
}
export { Sections };
