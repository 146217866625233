import { defineComponent, ref, watch, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { BaseCard } from "@/components/base/card/BaseCard/BaseCard";
import { NumberPicker } from "@/components/numberPicker/NumberPicker";
import { ThePayment } from "@/components/payment/ThePayment";
import { TextPicker } from "@/components/textPicker/TextPicker";
import { RangePicker } from "@/components/rangePicker/RangePicker";
import { subscriptionsStore } from "@/store/subscriptionsStore";
import { profileStore } from "@/store/profileStore";
import { serversStore } from "@/store/serversStore";
import { payStore } from "@/store/payStore";
import { authStore } from "@/store/authStore";
import "./style.scss";

const ThePayExtention = defineComponent({
  name: "ThePayExtention",
  setup() {
    // авторизация по токену
    const router = useRouter();
    const route = useRoute();
    const isChange = ref(true);
    const accessLevel = ref(1);
    const accessLevelOld = ref(0);
    const price = ref(0);
    const currentPrice = ref(0);
    const oldPrice = ref(0);
    const active_until = ref("");
    const bill = ref(0);
    const timerId = ref<number>();
    const countMount = ref(1);
    const countMountOld = ref(0);

    const products = ref([
      {
        name: "Trial",
        price: 0,
        quantity: 1,
        variant: "1 месяц",
      },
    ]);
  
    const closeTariff = () => {
      clearInterval(timerId.value);
      price.value = oldPrice.value;
      accessLevel.value = accessLevelOld.value;
      bill.value = 0;
      // isChange.value = false;
    };
  
    const convertDate = (date: string) => {
      const newDate: string[] = date.split(" ")[0].split("-");
      return `${newDate[2]}.${newDate[1]}.${newDate[0]}`;
    };
    const getPrice = () => {
      const tariff_plan =
        countMount.value === 0 && accessLevel.value === 0
          ? "trial"
          : `m_${countMount.value}_g_${accessLevel.value}`;
      subscriptionsStore
        .postSubscriptions(tariff_plan, "0", "0", "preview")
        .then(() => {
          if (subscriptionsStore.state.price) {
            currentPrice.value = subscriptionsStore.state.price;
            priceAnimate(subscriptionsStore.state.price);
          } else {
            priceAnimate(0);
          }
        });
    };
    const priceAnimate = (value: number) => {
      clearInterval(timerId.value);
      if (price.value < value) {
        const num = Math.ceil((value - price.value) / 250);
        timerId.value = setInterval(() => {
          if (price.value + num < value) {
            price.value += num;
          } else {
            price.value = value;
            clearInterval(timerId.value);
          }
        }, 5);
      } else {
        const num = Math.ceil((price.value - value) / 250);
        timerId.value = setInterval(() => {
          if (price.value - num > value) {
            price.value -= num;
          } else {
            price.value = value;
            clearInterval(timerId.value);
          }
        }, 5);
      }
    };

    const commerce = (
      accessLevel: number,
      countMount: number,
      method: string
    ) => {
      const price = ref(0);
      const ecommerce = ref();
      switch (accessLevel) {
        case 1:
          price.value = 99;
          break;
        case 2:
          price.value = 299;
          break;
        case 3:
          price.value = 499;
          break;
        default:
          price.value = 0;
          break;
      }
      price.value =
        countMount === 12 ? parseInt(`${price.value}9`) : price.value;
      const name = `${price.value} RUB/${countMount === 12 ? "год" : "месяц"}`;
      const variant = countMount === 1 ? "1 месяц" : "12 месяцев";
      products.value = [
        {
          name: name,
          price: price.value,
          quantity: 1,
          variant: variant,
        },
      ];
      if (method === "view") {
        ecommerce.value = {
          ecommerce: {
            currencyCode: "RUB",
            detail: {
              products: products.value,
            },
          },
        };
      }
      if (method === "cart") {
        localStorage.setItem("cart_name", name);
        localStorage.setItem("cart_price", price.value.toString());
        localStorage.setItem("cart_variant", variant);
        ecommerce.value = {
          ecommerce: {
            currencyCode: "RUB",
            add: {
              products: products.value,
            },
          },
        };
      }
      if (method === "pay") {
        const name = localStorage.getItem("cart_name");
        const price = localStorage.getItem("cart_price");
        const variant = localStorage.getItem("cart_variant");
        const payment_id = localStorage.getItem("payment_id");

        window.dataLayer.push({
          ecommerce: {
            currencyCode: "RUB",
            purchase: {
              actionField: {
                id: payment_id,
              },
              products: [
                {
                  name: name,
                  price: price,
                  variant: variant,
                  quantity: 1,
                },
              ],
            },
          },
        });
      }

      window.dataLayer.push(ecommerce.value);
    };
    if (typeof route.params.token === "string") {
      // authStore.setToken(route.params.token);
    };
    // Получение баланса
    profileStore.getBalance();
    const isButtonDisable = ref(false);

    // Баланс
    const real_money = computed(() => {
      if (profileStore.state.balance?.real_money) {
        return profileStore.state.balance.real_money;
      }
      return 0;
    });

    // Бонусный баланс
    const bonus_money = computed(() => {
      if (profileStore.state.balance?.bonus_money) {
        return profileStore.state.balance.bonus_money;
      }
      return 0;
    });

    // списание с баланса
    const payRealMoney = ref(0);

    // списание с бонусного баланса
    const payBonusMoney = ref(0);

    // Получение тарифного плана
    subscriptionsStore.getSubscriptions().then(() => {
      priceAnimate(
        subscriptionsStore.state.active_subscription?.tariff_plan?.price || 0
      );
      active_until.value =
        subscriptionsStore.state.active_subscription?.active_until;
      const alias: string[] =
        subscriptionsStore.state.active_subscription?.tariff_plan?.alias.split(
          "_"
        );
      if (alias) {
        accessLevel.value = alias[0] !== "trial" ? parseInt(alias[3]) : 1;
        countMount.value = alias[0] !== "trial" ? parseInt(alias[1]) : 1;
      } else {
        accessLevel.value = 1;
        countMount.value = 1;
      }
      accessLevelOld.value = accessLevel.value;
      countMountOld.value = countMount.value;
    });
    watch([accessLevel, countMount], () => {
      if (isChange.value) {
        commerce(accessLevel.value, countMount.value, "view");
        getPrice();
      }
    });

    const changeTariff = (element: MouseEvent, status?: string) => {
      if (!isChange.value) {
        oldPrice.value = price.value;
        isChange.value = true;
        commerce(accessLevel.value, countMount.value, "view");
        getPrice();
      } else {
        if (isButtonDisable.value) {
          return;
        }
        isButtonDisable.value = true;
        if (status === "Продлить") {
          window.ym(45609672, "reachGoal", "click_on_the_extend_button");
        }
        commerce(accessLevel.value, countMount.value, "cart");

        const tariff_plan =
          countMount.value === 0 && accessLevel.value === 0
            ? "trial"
            : `m_${countMount.value}_g_${accessLevel.value}`;
        subscriptionsStore
          .postSubscriptions(
            tariff_plan,
            payBonusMoney.value.toString(),
            payRealMoney.value.toString(),
            "bill",
            element
          )
          .then((response) => {
            if (response?.data.bill_ct) {
              localStorage.setItem("payment_id", response.data.bill_ct || "");
            }
            if (response?.data.bill_id) {
              localStorage.setItem(
                "payment_id",
                response?.data.bill_id.toString() || ""
              );
            }
            if (payBonusMoney.value === 0 && payRealMoney.value === 0) {
              if (response?.data.message === "Plan activated.") {
                bill.value = 0;
                // isChange.value = false;
                location.href = '/'
                profileStore.getBalance(true);
                commerce(accessLevel.value, countMount.value, "pay");
                serversStore.getServers();
                window.ym(45609672, "reachGoal", " successful_payment");
              } else {
                payStore.state.isShowPayment = true;
              }
            } else {
              bill.value = 0;
              // isChange.value = false;
              location.href = '/'
              serversStore.getServers();
              profileStore.getBalance(true);
            }
            isButtonDisable.value = false;
          });
      }
    };
    const date = () => {
      if (
        subscriptionsStore.state.active_subscription?.tariff_plan?.alias !==
          "trial" &&
        subscriptionsStore.state.active_subscription.status === "active"
      ) {
        return `до ${convertDate(active_until.value)}`;
      } else if (
        subscriptionsStore.state.active_subscription.status === "expired"
      ) {
        return '<span style="color: red;">истёк</span>';
      } else {
        return "пробный";
      }
    };
    const server_text = () => {
      if (subscriptionsStore.state.active_subscription.status === "expired") {
        return "Доступ к серверам ограничен";
      } else {
        if (accessLevel.value >= 1) {
          const server_count =
            accessLevel.value === 1
              ? accessLevel.value
              : accessLevel.value === 2
              ? 3
              : 5;
          return `Тариф предоставляет доступ к <span><strong>${server_count}</strong><span>${
            accessLevel.value === 1 ? " серверу" : " серверам"
          }</span></span>`;
        } else {
          return "Тариф предоставляет доступ к пробному серверу";
        }
      }
    };
    return () => (
      <BaseCard
        class={isChange.value ? "v-card--view" : null}
      >
        {payStore.state.isShowPayment && <ThePayment />}
        <div class={isChange.value ? "card-dev change" : "card-dev"}>
          <div class={"label"}>
            <span class={"label-number"}>{price.value}</span>
            <span class={"label-child"}>
              <div>RUB/{countMount.value === 12 ? 'ГОД' : 'МЕС'}</div>
              {!isChange.value && (
                <div style={"font-size: 20px;"} v-html={date()}></div>
              )}
            </span>
          </div>
          {isChange.value && bill.value === 1 ? (
            <>
              {!!real_money.value && (
                <RangePicker
                  min={0}
                  max={
                    real_money.value >= currentPrice.value
                      ? currentPrice.value
                      : real_money.value + bonus_money.value >=
                        currentPrice.value
                      ? real_money.value
                      : 0
                  }
                  label={"Баланс"}
                  onChange={(value: number) => (payRealMoney.value = value)}
                />
              )}
              {!!bonus_money.value && (
                <RangePicker
                  min={0}
                  max={
                    bonus_money.value > currentPrice.value
                      ? currentPrice.value
                      : real_money.value + bonus_money.value >=
                        currentPrice.value
                      ? bonus_money.value
                      : 0
                  }
                  label={"Бонусный баланс"}
                  onChange={(value: number) => (payBonusMoney.value = value)}
                />
              )}
            </>
          ) : (
            <>
              {isChange.value ? (
                <NumberPicker
                  active={accessLevel.value}
                  onChange={(num) => (accessLevel.value = num)}
                  numbers={[1, 2, 3]}
                  title={"Уровень доступа"}
                />
              ) : null}
              {isChange.value ? (
                <NumberPicker
                  active={countMount.value}
                  onChange={(num) => (countMount.value = num)}
                  numbers={[1, 12]}
                  title={"Количество месяцев"}
                />
              ) : null}
            </>
          )}
          {isChange.value &&
          price.value &&
          bonus_money.value + real_money.value >= price.value ? (
            <TextPicker
              texts={["С карты", "бонусный баланс"]}
              title={"счёт списания"}
              onChange={(num: number) => (bill.value = num)}
            />
          ) : null}
          <div class={"description"}>
            <div style="font-size: 11px;color: #545454;" v-html={server_text()}></div>
            <div
              class={"cursor-pointer button"}
              onClick={(e) =>
                changeTariff(
                  e,
                  subscriptionsStore.state.active_subscription.status ===
                    "expired"
                    ? "Продлить"
                    : accessLevel.value >= accessLevelOld.value
                    ? "Купить"
                    : "Перейти"
                )
              }
            >
              {subscriptionsStore.state.active_subscription.status === "expired"
                ? "Продлить"
                : accessLevel.value >= accessLevelOld.value
                ? "Купить"
                : "Перейти"}
            </div>
            <div
              class={"cursor-pointer button button--close"}
              onClick={closeTariff}
            >
              Закрыть
            </div>
          </div>
        </div>
      </BaseCard>
    );
  },
});

export { ThePayExtention };
