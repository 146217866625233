import { defineComponent, onMounted, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { IElement, ISection, Sections } from "./SidebarData";
import { sidebarStore } from "@/store/sidebarStore";
import { profileStore } from "@/store/profileStore";
import "./style.scss";

const active = (e: any) => {
  e.target.nextSibling.classList.toggle("sidebar__elements--active");
};

const TheSidebar = defineComponent({
  name: "TheSidebar",
  setup() {
    const router = useRouter();
    const sections: ISection[] = Sections;
    const isMobile = ref(window.innerWidth < 820);
    // const accessLevel = ref(1);
    const accessLevel = computed(() => {
      if (profileStore.state.balance.access_level) {
        return profileStore.state.balance.access_level;
      } else {
        return 1;
      }
    });
    // if (!profileStore.state.balance.access_level) {
    //   profileStore.getBalance(true).then(() => {
    //     if (profileStore.state.balance.access_level) {
    //       accessLevel.value = profileStore.state.balance.access_level;
    //     }
    //   });
    // }

    onMounted(() => {
      window.addEventListener("resize", () => {
        isMobile.value = window.innerWidth < 820;
      });
    });
    return () => (
      <div
        class={
          isMobile.value && sidebarStore.state.active
            ? "sidebar sidebar--show"
            : "sidebar"
        }
      >
        <div class="logo">
          {" "}
          DUBKI<span class="logo__secondary">VPN</span>
        </div>
        <div class="sidebar__section-box">
          {sections.map(
            (section: ISection) =>
              accessLevel.value >= section.access_level && (
                <div
                  class={
                    router.currentRoute.value.meta.selected === section.title
                      ? "sidebar__section--active"
                      : "sidebar__section"
                  }
                >
                  <div
                    onClick={(e) => {
                      if (section?.elements) {
                        active(e);
                      }
                      router.push(section.path);
                      if (section.method) {
                        section.method();
                      }
                    }}
                    class={"sidebar__title"}
                  >
                    <div class="sidebar__title-text">{section.title}</div>
                    <div
                      class="sidebar__icon"
                      v-html={section.icon ? section.icon : ""}
                    ></div>
                  </div>
                  {section?.elements && (
                    <div class={"sidebar__elements"}>
                      {section.elements.map(
                        (element: IElement) =>
                          accessLevel.value >= element.access_level && (
                            <div
                              onClick={() => {
                                router.push(element.path);
                                if (element.method) {
                                  element.method();
                                }
                              }}
                              class={"sidebar__element cursor-pointer"}
                            >
                              {element.title}
                            </div>
                          )
                      )}
                    </div>
                  )}
                </div>
              )
          )}
        </div>
      </div>
    );
  },
});

export { TheSidebar };
