import { reactive } from 'vue'

export interface IState {
  active: boolean;
}

const state = reactive<IState>({
  active: false,
})

export const sidebarStore = {
  state,
};

