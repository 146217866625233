import { defineComponent } from "vue";

const IconEmail = defineComponent({
  render() {
    return (
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 14C2 7.373 7.373 2 14 2C20.627 2 26 7.373 26 14C26 15.29 25.762 17.165 25.189 18.681C24.582 20.289 23.81 21 23 21C22.198 21 21.769 20.796 21.517 20.517C21.246 20.217 21 19.633 21 18.5V9C21 8.448 20.552 8 20 8C19.448 8 19 8.448 19 9V9.10101C17.729 7.80401 15.959 7 14 7C10.134 7 7 10.134 7 14C7 17.866 10.134 21 14 21C15.963 21 17.737 20.192 19.008 18.891C19.06 20.089 19.36 21.113 20.033 21.858C20.798 22.704 21.869 23 23 23C25.19 23 26.418 21.09 27.061 19.387C27.738 17.593 28 15.468 28 14C28 6.268 21.732 0 14 0C6.268 0 0 6.268 0 14C0 21.732 6.268 28 14 28C14.552 28 15 27.552 15 27C15 26.448 14.552 26 14 26C7.373 26 2 20.627 2 14ZM19 14C19 11.239 16.761 9 14 9C11.239 9 9 11.239 9 14C9 16.761 11.239 19 14 19C16.761 19 19 16.761 19 14Z"
          fill="currentColor"
        />
      </svg>
    );
  },
});

export { IconEmail };
