import { defineComponent, computed } from "vue";
import { useRouter } from "vue-router";
import { UsersCard } from "@/components/usersCard/UsersCard";
import { profileStore } from "@/store/profileStore";
import "./style.scss";

const Users = defineComponent({
  name: "Users",
  setup() {
    const router = useRouter();

    const accessLevel = computed(() => {
      if (profileStore.state.balance.access_level) {
        if (profileStore.state.balance.access_level < 20) {
          router.push("/overview");
        }
        return profileStore.state.balance.access_level;
      } else {
        return 1;
      }
    });

    return () =>
      accessLevel.value >= 20 && (
        <div>
          <UsersCard />
        </div>
      );
  },
});

export { Users };
