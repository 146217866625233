import { defineComponent, ref, computed, PropType } from "vue";
import { BaseTable } from "@/components/base/table/BaseTable/BaseTable";
import { serversStore } from "@/store/serversStore";
import { notificationStore } from "@/store/notificationStore";
import { BaseCard } from "@/components/base/card/BaseCard/BaseCard";
import "./style.scss";

const ServersCard = defineComponent({
  name: "ServersCard",
  setup() {
    const convertType = (row?: any) => {
      const type = row.type;
      if (!type) return "";
      if (type === "public") {
        return <span>{window.translate.public}</span>;
      }
      if (type === "private") {
        return <span>{window.translate.private}</span>;
      }
    };

    const copyOpenvpn = (id: number) => {
      const baseURL =
        process.env.VUE_APP_API_URL ?? "https://dvpn-api.dline-rhost.ru/api";
      const text = `${baseURL}/servers/${id}/profile`;
      copyBuff(text);
      notificationStore.state.notification.message =
        window.translate.openvpn_copy;
      notificationStore.state.notification.view = true;
    };

    const copyBuff = (text: string) => {
      const el = document.createElement("textarea");
      el.value = text;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    };

    const outputLink = (row?: any) => {
      const serverId = row.id;
      return (
        <div style="display: flex">
          <svg
            onClick={() => serversStore.getServerFile(serverId)}
            class="table__icon"
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_9_184)">
              <path
                d="M37.8872 24.5178H24.7825L23.0254 26.5163C22.2632 27.3843 21.158 27.884 20.0021 27.884C18.8462 27.884 17.7411 27.3843 16.979 26.5163L15.2218 24.5178H2.11708C0.94845 24.5178 0 25.4663 0 26.6349V35.0397C0 36.2083 0.94845 37.1568 2.11708 37.1568H37.8829C39.0516 37.1568 40 36.2083 40 35.0397V26.6349C40.0043 25.4663 39.0558 24.5178 37.8872 24.5178ZM5.89394 32.6432C4.89891 32.6432 4.09019 31.8344 4.09019 30.8393C4.09019 29.8443 4.89891 29.0356 5.89394 29.0356C6.88897 29.0356 7.69769 29.8443 7.69769 30.8393C7.69769 31.8344 6.88897 32.6432 5.89394 32.6432Z"
                fill="currentColor"
              />
              <path
                d="M17.758 24.5179L18.4101 25.2631C18.8335 25.7416 19.4178 25.9829 20.0021 25.9829C20.5864 25.9829 21.1707 25.7416 21.5942 25.2631L22.2462 24.5179L31.0829 14.4491C32.2854 13.0814 31.3115 10.9347 29.4909 10.9347H26.7132V4.96034C26.7132 3.79171 25.7648 2.84326 24.5962 2.84326H15.4081C14.2394 2.84326 13.291 3.79171 13.291 4.96034V10.939H10.5134C8.69271 10.939 7.72309 13.0857 8.92135 14.4533L17.758 24.5179Z"
                fill="currentColor"
              />
            </g>
            <defs>
              <clipPath id="clip0_9_184">
                <rect width="40" height="40" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div
            onClick={() => copyOpenvpn(serverId)}
            class="table__icon--copy"
            data-copy-label={window.translate.click_for_copying}
          >
            <svg
              class="table__icon"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_20_2)">
                <path
                  d="M26.3349 0H4.61548V30.0452H10.9503V7.23982H26.3349V0Z"
                  fill="currentColor"
                />
                <path
                  d="M27.6924 11.3122V17.647H34.0272L27.6924 11.3122Z"
                  fill="currentColor"
                />
                <path
                  d="M24.9773 20.362V9.95474H13.665V40H35.3845V20.362H24.9773Z"
                  fill="currentColor"
                />
              </g>
              <defs>
                <clipPath id="clip0_20_2">
                  <rect width="40" height="40" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      );
    };

    const columns = ref([
      {
        label: window.translate.id,
        field: "id",
        style: "width: 50px",
        counter: true,
      },
      {
        label: window.translate.name,
        field: "label",
        style: "min-width: 100px",
      },
      {
        label: window.translate.server_address,
        field: "dns",
        style: "min-width: 80px",
        copy: true,
      },
      {
        label: window.translate.psk,
        field: "psk",
        style: "min-width: 155px",
        copy: true,
      },
      {
        label: window.translate.type,
        field: "type",
        style: "min-width: 100px",
        action: convertType,
      },
      {
        label: "OpenVPN",
        field: "openvpn",
        style: "min-width: 100px",
        action: outputLink,
      },
    ]);

    const data = computed(() => {
      if (serversStore.state.servers.length) {
        return serversStore.state.servers;
      }
      return [];
    });
    return () => (
      <BaseCard
        class="v-card--scroll"
        style="max-width: 1010px;color: #fff; margin-right: 0;width: 100%;min-height: 234px;"
      >
        <BaseTable columns={columns.value} rows={data.value} />
      </BaseCard>
    );
  },
});

export { ServersCard };
