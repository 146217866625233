import { defineComponent, ref } from "vue";
import { BaseInput } from "@/components/base/input/BaseInput/BaseInput";
import { BaseButton } from "@/components/base/button/BaseButton/BaseButton";
import { IconEmail } from "@/assets/icons/IconEmail";
import { IconLock } from "@/assets/icons/IconLock";
import { authStore } from "@/store/authStore";
import { errorStore } from "@/store/errorStore";
import { useRouter } from "vue-router";
import "./style.scss";

const TheLogn = defineComponent({
  name: "TheLogn",
  setup() {
    const router = useRouter();
    const email = ref("");
    const confirm_code = ref("");
    const isDisable = ref(false);
    const isShowConfirmationCode = ref(false);
    const isEmailDisabled = ref(false);

    const error = ref([]);
    const errorConfirm = ref([]);

    const code = () => {
      isDisable.value = true;
      authStore
        .verificationCode(confirm_code.value)
        .then(() => {
          router.push("/overview");
        })
        .catch((e) => {
          if (e.response?.data?.data) {
            Object.keys(e.response.data.data).map((key: string) => {
              const error = e.response.data.data[key];
              errorConfirm.value.push(error as never);
            });
          }
          if (e.response?.data?.code) {
            e.response.data.code.map((error: string) => {
              errorConfirm.value.push(error as never);
            });
          }
        })
        .finally(() => {
          isDisable.value = false;
        });
      return false;
    };

    const auth = () => {
      isShowConfirmationCode.value = false;
      isDisable.value = true;
      authStore.state.form = {
        type: "email",
        value: email.value,
      };
      authStore
        .login()
        .then((r) => {
          localStorage.setItem("auth_email", email.value);
          if (r.data.session_id) {
            isShowConfirmationCode.value = true;
            isEmailDisabled.value = true;
          } else {
            router.push("/overview");
          }
        })
        .catch((e) => {
          if (e.response?.data?.value) {
            error.value.push(e.response.data.value as never);
          }
          errorStore.treatment("auth", e);
        })
        .finally(() => {
          isDisable.value = false;
        });
      return false;
    };
    const login = () => {
      error.value = [];
      errorConfirm.value = [];
      if (isShowConfirmationCode.value) {
        code();
      } else {
        auth();
      }
    };
    return () => (
      <div class="login">
        <div class="login__left">
          <div class="login__logo">DUBKIVPN</div>
        </div>
        <div class="login__wrapper">
          <form
            class="login__right"
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <div class="login__title">{window.translate.authorization}</div>
            <div class="login__groop">
              <BaseInput
                mode="none"
                type={"email"}
                onClick={login}
                disable={isEmailDisabled.value}
                placeholder={window.translate.email}
                error={error.value}
                onValue={(value) => (email.value = value)}
              >
                <IconEmail />
              </BaseInput>
            </div>
            {isShowConfirmationCode.value && (
              <div class="login__groop">
                <BaseInput
                  mode="none"
                  type={"number"}
                  onClick={login}
                  placeholder={window.translate.confirmation_code}
                  error={errorConfirm.value}
                  onValue={(value) => (confirm_code.value = value)}
                >
                  <IconLock />
                </BaseInput>
              </div>
            )}
            <div class="login__groop">
              <BaseButton disabled={isDisable.value} onClick={login} value={window.translate.log_in} />
            </div>
          </form>
          <div class="login__app">
            <a
              href="https://apps.apple.com/ru/app/dubkivpn-fast-secure-vpn/id1628019423"
              target="__blank"
            >
              App Store
            </a>
            {" | "}
            <a
              href="https://play.google.com/store/apps/details?id=ru.dubki.vpn"
              target="__blank"
            >
              Google Play
            </a>
          </div>
        </div>
      </div>
    );
  },
});

export { TheLogn };
