import { defineComponent, computed, ref } from "vue";
import Cookies from "js-cookie";
import { confirmEmailStore } from "@/store/ConfirmEmail";
import { profileStore } from "@/store/profileStore";
import { ReconfirmationsPopup } from "../../components/popup/reconfirmation/Reconfirmation";
import "./style.scss";

const ConfirmEmail = defineComponent({
  name: "ConfirmEmail",
  setup() {
    const isEmailReconfirmation = ref(false);
    const emailReconfirmation = () => {
      isEmailReconfirmation.value = true;
    };
    const isView = computed(() => {
      if (profileStore.state.balance.email_confirmed === null) {
        return false;
      } else if (!profileStore.state.balance.email_confirmed) {
        return true;
      } else {
        return false;
      }
    });

    const close = () => {
      Cookies.remove("is_new_user");
      confirmEmailStore.state.isView = false;
      profileStore.state.balance.email_confirmed = null;
    };

    return () =>
      isView.value && (
        <div class={"confirm-email"}>
          <div>
            <ReconfirmationsPopup
              onClose={() => (isEmailReconfirmation.value = false)}
              show={isEmailReconfirmation.value}
            />
            <span class={"confirm-email__label"}>
              {window.translate.email_not_confirmed}
            </span>
            <span class="confirm-email__repeat" onClick={emailReconfirmation}>
              {window.translate.confirm}
            </span>
          </div>
          <div class={"confirm-email__close"} onClick={close}>
            &#10005;
          </div>
        </div>
      );
  },
});

export { ConfirmEmail };
