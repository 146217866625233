import { defineComponent, PropType, computed } from "vue";
import { searchStore } from "@/store/searchStore";
import "./style.scss";

const BaseSearch = defineComponent({
  name: "BaseSearch",
  props: {
    button: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    filters: {
      type: Object,
      default: {},
    },
    searchActiveFilter: {
      type: String,
      default: "",
    },
    onFilter: {
      type: Function as PropType<(filter: string) => void>,
    },
    onSearch: {
      type: Function as PropType<(value: string) => void>,
    },
  },
  setup(props, { emit }) {
    searchStore.state.filter = props.searchActiveFilter || Object.keys(props.filters)[0];
    const change = (filter: string) => {
      searchStore.state.filter = filter;
      emit("filter", filter);
    };
    const changeValue = (e: any) => {
      searchStore.state.value = e.target.value;
      if (!props.button) {
        search();
      }
    };
    const search = () => {
      emit("search", searchStore.state.value);
    };
    const selectFilter = computed(() => searchStore.state.filter);

    const keyDown = (event: any) => {
      if(props.button && event.keyCode === 13) { // key Enter
        search();
      }
    };
    return () => (
      <div class="base-search">
        <div class="base-search__wrapper">
          <input
            class="base-search__input"
            style={
              props.button
                ? "padding: 10px 20px 10px 50px"
                : "padding: 10px 50px"
            }
            type="text"
            value={searchStore.state.value}
            onInput={(e: any) => changeValue(e)}
            placeholder={props.placeholder}
            onKeydown={keyDown}
          />
          {props.button && (
            <button onClick={search}>{window.translate.find}</button>
          )}
        </div>
        <div class="base-search__filters">
          {Object.keys(props.filters).map((key: string) => {
            return (
              <span class="base-search__filter">
                <input
                  type="radio"
                  id={key}
                  name="filter"
                  value={key}
                  checked={selectFilter.value == key}
                />
                <label for={key} onClick={() => change(key)}>
                  {props.filters[key]}
                </label>
              </span>
            );
          })}
        </div>
      </div>
    );
  },
});

export { BaseSearch };
