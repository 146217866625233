import "./assets/scss/global.scss";
import { defineComponent, computed } from "vue";
import { UnderProtect } from "./components/underProtect/UnderProtect";
import { Notification } from "./components/notification/Notification";
import { Promocodes } from "./components/popup/promocodes/Promocodes";
import { ConfirmEmail } from "./components/confirmEmail/ConfirmEmail";
import { Smartbanner } from "./components/smartbanner/Smartbanner";
import Cookies from "js-cookie";

const locales = computed(() => {
  const path = require.context("@/locales", false, /\.json$/);
  const keys = path.keys();

  let locale = localStorage.getItem("locale");
  if (!locale) {
    const lang = navigator.language;
    locale = lang.split("-")[0];
    localStorage.setItem("locale", locale);
  }

  if (keys.length) {
    let locales: { [key: string]: any } = {};
    keys.forEach((key) => {
      locales[key.replace(/.\/|.json/g, "")] = path(key);
    });
    return locales;
  } else {
    return [];
  }
});

window.locales = Object.keys(locales.value);

const translate = () => {
  const locale = Cookies.get('dvpn_auth_locale') || "ru";
  if (locales.value[locale]) {
    window.translate = locales.value[locale];
    return locales.value[locale];
  } else {
    window.translate = locales.value["ru"];
    return locales.value["ru"];
  }
};

translate();

const App = defineComponent({
  name: "App",
  setup() {
    return () => (
      <div style={"height: 100%;width: 100%;"}>
        <Smartbanner
          title={"DubkiVPN. Fast & Secure VPN."}
          author={"DLine Media"}
          price={"БЕСПЛАТНО - в Google Play"}
          button={"Смотреть"}
          url={"https://play.google.com/store/apps/details?id=ru.dubki.vpn"}
        />
        <UnderProtect />
        <ConfirmEmail />
        <Promocodes />
        <router-view />
        <Notification />
      </div>
    );
  },
});

export { App, translate };
