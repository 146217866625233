import { reactive, computed } from 'vue'
import { IList, promocodesService } from '@/services/promocodeService'

const state = reactive({
  error: null,
  mode: null,
  isPromocode: false,
  promocode_activations: 0,
  last_activation: '',
  earned_bonus_coins: 0,
  list: <IList[]>[]
})

const actions = {
  async promocode(promocode: string) {
    try {
      const response = await promocodesService.post(promocode);
      state.mode = response.data.data.promocode_mode;
      state.error = null;
    } catch (e) {
      const error = e.response.data.data.error_details;
      state.error = error;
    }
  },
  async createPersonalCode() {
    state.error = null;
    try {
      const response = await promocodesService.create();
      state.list.push(response.data.data);
    } catch (e) {
      const error = e.response.data.data.error_details;
      state.error = error;
    }
  },
  async listPersonalCode() {
    state.error = null;
    try {
      const response = await promocodesService.list();
      state.list = response.data.data;
    } catch (e) {
      const error = e.response.data.data.error_details;
      state.error = error;
    }
  },
  async statsPersonalCode() {
    try {
      state.error = null;
      const response = await promocodesService.stats();
      state.promocode_activations = response.data.data.promocode_activations;
      state.earned_bonus_coins = response.data.data.earned_bonus_coins;
      if (response.data.data.last_activation) {
        state.last_activation = response.data.data.last_activation;
      }
    } catch(e) {
      const error = e.response.data.data.error_details;
      state.error = error;
    }
  },
}

export const promocodeStore = {
  state,
  error: computed(() => state.error),
  ...actions,
};

