import { defineComponent, ref, PropType, computed } from "vue";
import "./style.scss";

const BasePagination = defineComponent({
  name: "BasePagination",
  props: {
    pages: {
      type: Number,
      required: true as true,
    },
    onChange: {
      type: Function as PropType<(active: number) => void>
    }
  },
  setup(props, { emit, slots }) {
    const active = ref(1);
    const items = computed(() => {
      var temp = ref<any[]>([]);
      if (props.pages < 7) {
        for (var i = 1; i < props.pages + 1; i++) {
          temp.value.push(i);
        }
        return temp.value;
      } else {
        for (var i = 1; i < props.pages + 1; i++) {
          if (i === 1) {
            temp.value.push(i);
          } else if (i === active.value - 1) {
            temp.value.push(i);
          } else if (i === active.value) {
            temp.value.push(i);
          } else if (i === active.value + 1) {
            temp.value.push(i);
          } else if (i === active.value + 2 && i < 4) {
            temp.value.push(i);
          } else if (active.value === props.pages && i === props.pages - 2) {
            temp.value.push(i);
          } else if (i === props.pages) {
            temp.value.push(i);
          }
        }

        if (active.value > 3) {
          temp.value.splice(1, 0, "...");
        }
        if (active.value < props.pages - 2) {
          temp.value.splice(temp.value.length - 1, 0, "...");
        }
        return temp.value;
      }
    });

    const select = (value: any) => {
      if (typeof value === "number") {
        active.value = value;
        emit('change', value);
      }
    };
    const firstPage = () => {
      select(1);
    };
    const nextPage = () => {
      if (active.value + 1 <= props.pages) {
        select(active.value + 1);
      }
    };
    const prevPage = () => {
      if (active.value - 1 > 0) {
        select(active.value - 1);
      }
    };
    const lastPage = () => {
      select(props.pages);
    };
    return () => (
      <ul class="base-pagination">
        <li
          class={
            active.value === 1
              ? "base-pagination__item base-pagination__item--not-active"
              : "base-pagination__item"
          }
          onClick={firstPage}
        >
          «
        </li>
        <li
          class={
            active.value - 1 < 1
              ? "base-pagination__item base-pagination__item--not-active"
              : "base-pagination__item"
          }
          onClick={prevPage}
        >
          ‹
        </li>
        {items.value.map((i: any) => {
          return (
            <li
              class={
                active.value === i
                  ? "base-pagination__item base-pagination__item--active"
                  : "base-pagination__item"
              }
              onClick={() => select(i)}
            >
              {i}
            </li>
          );
        })}
        <li
          class={
            active.value + 1 > props.pages
              ? "base-pagination__item base-pagination__item--not-active"
              : "base-pagination__item"
          }
          onClick={nextPage}
        >
          ›
        </li>
        <li
          class={
            active.value === props.pages
              ? "base-pagination__item base-pagination__item--not-active"
              : "base-pagination__item"
          }
          onClick={lastPage}
        >
          »
        </li>
      </ul>
    );
  },
});

export { BasePagination };
