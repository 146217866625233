import { defineComponent, ref, onMounted, computed, PropType } from "vue";
import { BaseCard } from "@/components/base/card/BaseCard/BaseCard";
import { profileStore } from "@/store/profileStore";
import { subscriptionsStore } from "@/store/subscriptionsStore";
import "./style.scss";

const AuthDataCard = defineComponent({
  name: "AuthDataCard",
  setup() {
    const textLogin = ref(window.translate.copy);
    const textPassword = ref(window.translate.copy);
    const isCopyLogin = ref(true);
    const isCopyPass = ref(true);
    const clientWidth = ref(document.documentElement.clientWidth);
    const isNotMobile = ref(clientWidth.value > 440);

    const isTrial = computed(() => {
      if (
        subscriptionsStore.state.active_subscription.status !== "expired" &&
        subscriptionsStore.state.active_subscription.tariff_plan.alias ===
        "trial"
      ) {
        return true;
      } else {
        return false;
      }
    });

    onMounted(() => {
      window.addEventListener("resize", () => {
        clientWidth.value = document.documentElement.clientWidth;
      });
    });

    const copyBuff = (element: number, text: string | null) => {
      if (text) {
        const el = document.createElement("textarea");
        el.value = text;
        el.setAttribute("readonly", "");
        el.style.position = "absolute";
        el.style.left = "-9999px";
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        if (element === 0 && isCopyLogin.value) {
          textLogin.value = window.translate.copied;
          isCopyLogin.value = false;
          const timerLogin = setTimeout(() => {
            textLogin.value = window.translate.copy;
            isCopyLogin.value = true;
            clearTimeout(timerLogin);
          }, 5000);
        }
        if (element === 1 && isCopyPass.value) {
          textPassword.value = window.translate.copied;
          isCopyPass.value = false;
          const timerPass = setTimeout(() => {
            textPassword.value = window.translate.copy;
            isCopyPass.value = true;
            clearTimeout(timerPass);
          }, 5000);
        }
      }
    };
    return () => (
      <BaseCard style={isNotMobile.value ? "width: 283px;" : ""}>
        <div class="auth-data-card">
          <div style={"margin-bottom: 10px;"}>
            <div
              style={isTrial.value ? "color: #545454;" : "color: #fff;"}
              class="auth-data-card__label"
            >
              {window.translate.login}
            </div>
            <div
              class={
                isCopyLogin.value === false
                  ? "auth-data-card__value cursor-pointer color-green"
                  : "auth-data-card__value cursor-pointer"
              }
              onClick={() =>
                copyBuff(0, profileStore.state.connection.username)
              }
            >
              {textLogin.value}
            </div>
          </div>
          <div>
            <div
              style={isTrial.value ? "color: #545454;" : "color: #fff;"}
              class="auth-data-card__label"
            >
              {window.translate.password}
            </div>
            <div
              class={
                isCopyPass.value === false
                  ? "auth-data-card__value cursor-pointer color-green"
                  : "auth-data-card__value cursor-pointer"
              }
              onClick={() =>
                copyBuff(1, profileStore.state.connection.password)
              }
            >
              {textPassword.value}
            </div>
          </div>
        </div>
      </BaseCard>
    );
  },
});

export { AuthDataCard };
