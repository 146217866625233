import { defineComponent, PropType, ref } from "vue"
import './style.scss'

const TextPicker = defineComponent({
  name: "TextPicker",
  props: {
    texts: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    active: {
      type: Number,
      default: 0,
    },
    onChange: Function as PropType<(num: number) => void>,
  },
  setup(props, { emit }) {
    const isActive = ref(props.active);
    return () => (
      <div class={'text-picker'}>
        <div class={'text-picker__texts'} style={props.texts.length === 1 ? "justify-content: center" : ""}>
          {props.texts.length ?
            props.texts.map((text, index) => (
              <div onClick={() => {
                emit('change', index);
                isActive.value = index;
              }} class={text === props.texts[isActive.value] ? 'cursor-pointer text-picker__text text-picker__text--active' : 'cursor-pointer text-picker__text'}>{text}</div>
            )) : null}
        </div>
        <div class={'text-picker__label'}>{props.title}</div>
      </div>
    );
  },
});

export { TextPicker };
