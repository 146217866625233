import { defineComponent, watch, ref } from "vue"
import { subscriptionsStore } from "@/store/subscriptionsStore"
import "./style.scss"

declare global {
  interface Window {
      YooMoneyCheckoutWidget: any;
  }
}

const ThePayment = defineComponent({
  name: "ThePayment",
  setup() {
    const payment = ref(null)
    watch(payment, () => {
      // Инициализация виджета. Все параметры обязательные.
      if (subscriptionsStore.state.bill_ct && subscriptionsStore.state.bill_ct.length) {
        localStorage.setItem('payment_id', subscriptionsStore.state.bill_ct)
        const checkout = new window.YooMoneyCheckoutWidget({
          confirmation_token: subscriptionsStore.state.bill_ct, // Токен, который перед проведением оплаты нужно получить от ЮKassa
          return_url: `${process.env.VUE_APP_PATH}/overview?successful_payment=true`, // Ссылка на страницу завершения оплаты
          customization: {
            modal: true
          },
          error_callback: function(error: any) {
            console.warn(error)
          }
        });
        // Отображение платежной формы в контейнере
        checkout.render('payment-form')
      }
    })
    return () => (
      <div class="payment">
        <div id="payment-form" ref={payment}></div>
      </div>
    )
  }
})

export { ThePayment }
