import { defineComponent, onMounted, ref } from "vue";
import "./style.scss";

const MacConnect = defineComponent({
  name: "MacConnect",
  setup() {
    const clientWidth = ref(document.documentElement.clientWidth);
    onMounted(() => {
      window.addEventListener("resize", () => {
        clientWidth.value = document.documentElement.clientWidth;
      });
    });
    return () => (
      <div class="connection">
        <div class={"connection-mac__title"}>
          {window.translate.macos_instruction_title}
        </div>
        <div>{window.translate.macos_instruction_step_1}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-mac__image--900"
                : "connection-mac__image"
            }
            src={require("@/assets/images/connections/macos/01.png")}
            alt=""
          />
        </div>
        <div>{window.translate.macos_instruction_step_2}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-mac__image--900"
                : "connection-mac__image"
            }
            src={require("@/assets/images/connections/macos/02.png")}
            alt=""
          />
        </div>
        <div>{window.translate.macos_instruction_step_3}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-mac__image--900"
                : "connection-mac__image"
            }
            src={require("@/assets/images/connections/macos/03.png")}
            alt=""
          />
        </div>
        <div>{window.translate.macos_instruction_step_4}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-mac__image--900"
                : "connection-mac__image"
            }
            src={require("@/assets/images/connections/macos/04.png")}
            alt=""
          />
        </div>
        <div>{window.translate.macos_instruction_step_5}</div>
        <div>{window.translate.macos_instruction_step_6} </div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-mac__image--900"
                : "connection-mac__image"
            }
            src={require("@/assets/images/connections/macos/05.png")}
            alt=""
          />
        </div>
        <div>{window.translate.macos_instruction_step_7}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-mac__image--900"
                : "connection-mac__image"
            }
            src={require("@/assets/images/connections/macos/5.1.png")}
            alt=""
          />
        </div>
        <div>{window.translate.macos_instruction_step_8}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-mac__image--900"
                : "connection-mac__image"
            }
            src={require("@/assets/images/connections/macos/06.png")}
            alt=""
          />
        </div>
        <div>{window.translate.macos_instruction_step_9}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--900"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/macos/under-protect.jpg")}
            alt=""
          />
        </div>
        <div>{window.translate.macos_instruction_step_10}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-mac__image--900"
                : "connection-mac__image"
            }
            src={require("@/assets/images/connections/macos/07.png")}
            alt=""
          />
        </div>
      </div>
    );
  },
});

export { MacConnect };
