import { reactive } from "vue";
import { serversService, IServer } from "@/services/serversService";
import { errorStore } from "./errorStore";

export interface IState {
  servers: IServer[];
  config: string;
}
const state = reactive<IState>({
  servers: [],
  config: "",
});

const actions = {
  async getServers() {
    try {
      const { data } = await serversService.get();
      state.servers = data.data;
      return data.data;
    } catch (e) {
      errorStore.treatment("getServers", e);
      return e;
    }
  },
  async getServerFile(id: number) {
    try {
      state.config = "";
      const response = await serversService.getFile(id);
      const filename = response.headers["content-disposition"]
        .split("filename=")[1]
        .replaceAll('"', "");
      state.config = response.data;
      const blob = new Blob([state.config], { type: "application/txt" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      URL.revokeObjectURL(link.href);
    } catch (e) {
      errorStore.treatment("getServers", e);
    }
  },
};

export const serversStore = {
  state,
  ...actions,
};
