import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { businessStore } from "@/store/businessStore";
import { BaseLoading } from "@/components/base/loading";
import "./style.scss";

export const CompanyCreate = defineComponent({
  name: "page-company-create",
  setup() {
    const router = useRouter();
    const name = ref("");
    const companyDomainName = ref("");
    const domainAuth = ref(false);
    const isError = ref(false);
    const loading = ref(false);

    const createCompany = () => {
      loading.value = true;
      isError.value = false;
      const data = {
        name: name.value,
        company_domain_name: companyDomainName.value,
        domain_auth: domainAuth.value,
      };

      businessStore
        .clientCreate(data)
        .then(() => {
          router.push({
            name: "company-list",
          });
          loading.value = false;
        })
        .catch(() => {
          isError.value = true;
          loading.value = false;
        });
    };

    return () => (
      <div class="company__wrapper">
        {loading.value ? (
          <div class="loading__wrapper">
            <BaseLoading />
          </div>
        ) : (
          <div>
            <label>
              <span>Имя</span>
              <input
                value={name.value}
                onInput={(e: any) => (name.value = e.target.value)}
                type="text"
              />
            </label>
            <label>
              <span>Домен</span>
              <input
                value={companyDomainName.value}
                onInput={(e: any) => (companyDomainName.value = e.target.value)}
                type="text"
              />
            </label>
            <label data-type="checkbox">
              <span>Доменная авторизация</span>
              <input
                checked={domainAuth.value}
                onChange={(e: any) => (domainAuth.value = e.target.checked)}
                type="checkbox"
              />
            </label>
            {isError.value && (
              <div class="error">Не удалось добавить компанию</div>
            )}
            <button onClick={createCompany}>Добавить</button>
          </div>
        )}
      </div>
    );
  },
});
