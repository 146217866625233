import { defineComponent, ref } from "vue";
import { subscriptionsStore } from "@/store/subscriptionsStore";
import { ISubscription } from "@/services/subscriptionsService";
import { IColumn, BaseTable } from "@/components/base/table/BaseTable/BaseTable";
import { BaseCard } from "@/components/base/card/BaseCard/BaseCard";

export interface ITariffPlan {
  alias: string;
  period_base: string;
  period_length: number;
  price: number;
}
const convertStatus = (row?: any) => {
  const status = row.status;
  if (!status) return "";
  if (status === "expired") {
    return <span>{window.translate.expired}</span>;
  }
  if (status === "downgraded") {
    return <span>{window.translate.downgraded}</span>;
  }
  if (status === "upgraded") {
    return <span>{window.translate.upgraded}</span>;
  }
  if (status === "active") {
    return <span style="color: #6fae6b; font-weight: bold;">{window.translate.active}</span>;
  }
};

const convertTariffPlan = (row?: any) => {
  const tariffPlan: ITariffPlan = row.tariff_plan;
  if (tariffPlan.alias === "trial") {
    return <span>{window.translate.trial}</span>;
  }
  return (
    <span>
      <strong>{tariffPlan.price}</strong>{window.translate.currency_for_rub}{" "}
      {tariffPlan.period_length === 12 ? window.translate.months : 
window.translate.month
      }
    </span>
  );
};

const columns: IColumn[] = [
  {
    label: "#",
    field: "id",
    style: "min-width: 20px",
    counter: true,
  },
  {
    label: window.translate.tariff_plan,
    field: "tariff_plan",
    style: "min-width: 150px",
    action: convertTariffPlan,
  },
  {
    label: window.translate.started,
    field: "active_from",
    style: "min-width: 150px",
  },
  {
    label: window.translate.ends,
    field: "active_until",
    style: "min-width: 150px",
  },
  {
    label: window.translate.status,
    field: "status",
    style: "min-width: 50px",
    action: convertStatus,
  },
];

const HistoryTariffCard = defineComponent({
  name: "HistoryTariffCard",
  setup() {
    const data = ref<ISubscription[]>([]);
    subscriptionsStore.getSubscriptions().then(() => {
      data.value = subscriptionsStore.state.subscriptions;
    });
    return () => (
      <BaseCard class="v-card--scroll" style="max-width: 1000px;color: #fff; margin-right: 0;width: 100%;min-height: 234px;">
        <BaseTable columns={columns} rows={data.value} reverse={true} />
      </BaseCard>
    );
  },
});

export { HistoryTariffCard };
