import { defineComponent, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { businessStore } from "@/store/businessStore";
import { BaseLoading } from "@/components/base/loading";
import "./style.scss";

export const CompanyUpdate = defineComponent({
  name: "page-company-update",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const name = ref("");
    const companyDomainName = ref("");
    const domainAuth = ref(false);
    const isError = ref(false);
    const errorMessage = ref("Не удалось удалить компанию");
    const loading = ref(true);
    const isNotFound = ref(false);

    const companyId = route.params.companyId;
    if (typeof companyId === "string") {
      businessStore
        .clientGet(Number(companyId))
        .then((response: any) => {
          if (response?.data?.data) {
            const company = response.data.data;
            name.value = company.name;
            companyDomainName.value = company.company_domain_name;
            domainAuth.value = Boolean(company.domain_auth);
          }
        })
        .catch(() => {
          isNotFound.value = true;
        })
        .finally(() => {
          loading.value = false;
        });
    }

    const removeCompany = () => {
      if (typeof companyId === "string") {
        loading.value = true;
        businessStore
          .clientRemove(companyId)
          .then(() => {
            router.push({
              name: "company-list",
            });
            loading.value = false;
          })
          .catch(() => {
            errorMessage.value = "Не удалось удалить компанию";
            isError.value = true;
            loading.value = false;
          });
      }
    };

    const updateCompany = () => {
      if (typeof companyId === "string") {
        loading.value = true;
        isError.value = false;
        const data = {
          id: Number(companyId),
          data: {
            name: name.value,
            company_domain_name: companyDomainName.value,
            domain_auth: domainAuth.value,
          },
        };

        businessStore
          .clientUpdate(data)
          .then(() => {
            router.push({
              name: "company-list",
            });
            loading.value = false;
          })
          .catch(() => {
            errorMessage.value = "Не удалось обновить компанию";
            isError.value = true;
            loading.value = false;
          });
      }
    };

    return () => (
      <div class="company__wrapper">
        {loading.value ? (
          <div class="loading__wrapper">
            <BaseLoading />
          </div>
        ) : isNotFound.value ? (
          <div class="not-found">Нет данных</div>
        ) : (
          <div>
            <label>
              <span>Имя</span>
              <input
                value={name.value}
                onInput={(e: any) => (name.value = e.target.value)}
                type="text"
              />
            </label>
            <label>
              <span>Домен</span>
              <input
                value={companyDomainName.value}
                onInput={(e: any) => (companyDomainName.value = e.target.value)}
                type="text"
              />
            </label>
            <label data-type="checkbox">
              <span>Доменная авторизация</span>
              <input
                checked={domainAuth.value}
                onChange={(e: any) => (domainAuth.value = e.target.checked)}
                type="checkbox"
              />
            </label>
            {isError.value && (
              <div class="error">Не удалось обновить данные компании</div>
            )}
            <div>
              <button onClick={updateCompany} style="margin-right: 20px">
                Сохранить
              </button>
              <button onClick={removeCompany}>Удалить</button>
            </div>
          </div>
        )}
      </div>
    );
  },
});
