import axios from 'axios';
import Cookies from 'js-cookie'
import * as FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

const fpPromise = FingerprintJS.load({ apiKey: 'ovmoT0F3ClceU251slzR' });

const baseURL = process.env.VUE_APP_API_URL;

const endpoints = ['/login', '/register'];

const httpClient = axios.create({ baseURL });

httpClient.interceptors.request.use((config) => {
  const token = Cookies.get('dvpn_auth_token') || '';

  if (config.url === undefined) return config;

  const loginOrRegister = endpoints.includes(config.url);

  if (!loginOrRegister) {
    config.headers!.Authorization = `Bearer ${token}`;
  }
  return fpPromise
  .then(fp => fp.get())
  .then(result => {
    config.headers!['X-fp-id'] = result.visitorId;
    return config;
  }).catch(() => {
    return config;
  });
});

export { httpClient };

