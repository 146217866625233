import { httpClient } from '@/http'

export interface IPromocodeRequest {
  promocode: string;
}

export interface IPromocodeResponse {
  [key: string]: any
}
export interface IPersonaleCodeResponse {
  [key: string]: any
}

interface IStats {
  data: {
    data: {
      promocode_activations: number;
      earned_bonus_coins: number;
      last_activation: string|null;
    }
  }
}

export interface IList {
  created_at: string;
  id: number;
  mode: string;
  promocode: string;
  valid_after: string;
  valid_before: string|null
}

interface ILists {
  data: {
    data: IList[]
  }
}
interface ICreate {
  data: {
    data: IList
  }
}

class PromocodesService {
  public post(promocode: string) {
    return httpClient.post<IPromocodeRequest, IPromocodeResponse>('/promocodes/validate', { promocode })
  }
  // Выпуск персонального промокода
  public create(): Promise<ICreate> {
    return httpClient.post('/promocodes/personal')
  }
  // Получить список персональных промокодов
  public list(): Promise<ILists> {
    return httpClient.get('/promocodes/personal')
  }
  // Показывает общее количество активаций персональных промокодов
  public stats(): Promise<IStats> {
    return httpClient.get('/promocodes/personal/stats')
  }
}

export const promocodesService = new PromocodesService
