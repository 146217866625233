import { reactive } from "vue";
import { adminService } from "@/services/adminService";

interface IMeta {
  current_page: number;
  last_page: number;
  per_page: number;
  total: number;
}

export interface IState {
  activePage: number;
  users: any[];
  user: any;
  meta: IMeta|null;
  filters: {
    [key: string]: string;
  };
  filterActive: string;
  filterValue: string;
}

export interface IBill {
  account_id: number;
  amount: number;
  amount_from_bonus: number;
  amount_from_rub: number;
  client_id: number;
  created_at: string;
  id: number;
  paid_at: string;
  payment_method: string;
  renewal: number;
  status: string;
  tariff_plan_id: number;
  updated_at: string;
  yookassa_confirmation_url: string|null;
  yookassa_payment_ct: string|null;
  yookassa_payment_id: string;
}

export interface ICard {
  account_id: number;
  client_id: number;
  created_at: string;
  details: string;
  id: number;
  payment_method: string;
  payment_system: string;
  status: string;
  token: string;
  updated_at: string;
}
export interface IUser {
  client_id: number;
  confirmed_at: string;
  created_at: string;
  created_from: string;
  id: number;
  type: string;
  updated_at: string;
  value: string;
}

const state = reactive<IState>({
  activePage: 1,
  users: [],
  user: null,
  filters: {
    email: "E-mail",
    ip: "IP",
    bill: "Интервал даты и времени в платежах",
    card: "По номеру карты",
  },
  filterActive: "email",
  filterValue: "",
  meta: null,
});

const actions = {
  async users() {
    try {
      state.users = [];
      const { data } = await adminService.users(state.activePage, {
        filter: state.filterActive,
        value: state.filterValue,
      });
      state.users = data.data;
      state.meta = data.meta;
      return data;
    }
    catch(e) {
      state.users = [];
      state.meta = null;
    }
  },
  async user(id: number) {
    const { data } = await adminService.user(id);
    state.user = data;
    return data;
  },
};

export const adminStore = {
  state,
  ...actions,
};
