import { reactive } from 'vue'

const state = reactive({
  isView: false
})

export const confirmEmailStore = {
  state,
};

