import { reactive } from "vue";
import {
  subscriptionsService,
  ISubscription,
} from "@/services/subscriptionsService";
import { errorStore } from "@/store/errorStore";

export interface IState {
  subscriptions: ISubscription[];
  price: number | null;
  price_after_calculation: number | null;
  active_subscription: ISubscription;
  bill_ct: string | null;
}
const state = reactive<IState>({
  subscriptions: [],
  bill_ct: null,
  price: null,
  price_after_calculation: null,
  active_subscription: {
    active_from: "",
    active_until: "",
    id: null,
    renewable: null,
    status: "",
    tariff_plan: {
      alias: "",
      period_base: "",
      period_length: null,
      price: null,
    },
  },
});

const actions = {
  async getSubscriptions() {
    try {
      const { data } = await subscriptionsService.get();
      state.subscriptions = data.data;
      if (state.subscriptions.length) {
        // state.active_subscription =
        //   state.subscriptions[state.subscriptions.length - 1];
        state.active_subscription = state.subscriptions[0];
      }
    } catch (e) {
      errorStore.treatment("getSubscriptions", e);
    }
  },
  async postSubscriptions(
    tariff_plan: string,
    use_bonus: string,
    use_balance: string,
    action: string,
    element?: MouseEvent
  ) {
    errorStore.deleteError();
    const { data } = await subscriptionsService.post(
      tariff_plan,
      use_bonus,
      use_balance,
      action
    );
    state.price = data.data.price;
    state.price_after_calculation = data.data.price_after_calculation;
    state.bill_ct = data.data.bill_ct;
    return data;
  },
};

export const subscriptionsStore = {
  state,
  ...actions,
};
