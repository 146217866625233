import { defineComponent, ref, onMounted } from "vue";
import { BasePlayer } from "@/components/base/player/BasePlayer";
import "./style.scss";

const IosConnect = defineComponent({
  name: "IosConnect",
  setup() {
    const clientWidth = ref(document.documentElement.clientWidth);
    const url = process.env.VUE_APP_INSTRUCTION_URL;
    onMounted(() => {
      window.addEventListener("resize", () => {
        clientWidth.value = document.documentElement.clientWidth;
      });
    });
    const videoList = [
      {
        name: "IPsec",
        poster: require("@/assets/images/connections/ios/preview/native-ipsec.webp"),
        list: [
          `${url}/tutorials/native/ios-ipsec.webm`,
          `${url}/tutorials/native/ios-ipsec.ogg`,
          `${url}/tutorials/native/ios-ipsec.mp4`,
        ],
      },
      {
        name: "OpenVPN",
        poster: require("@/assets/images/connections/ios/preview/openvpn.webp"),
        list: [
          `${url}/tutorials/openvpn/ios.webm`,
          `${url}/tutorials/openvpn/ios.ogg`,
          `${url}/tutorials/openvpn/ios.mp4`,
        ],
      },
      {
        name: "L2TP",
        poster: require("@/assets/images/connections/ios/preview/native-l2tp.webp"),
        list: [
          `${url}/tutorials/native/ios-l2tp.webm`,
          `${url}/tutorials/native/ios-l2tp.ogg`,
          `${url}/tutorials/native/ios-l2tp.mp4`,
        ],
      },
    ];
    return () => (
      <div class="connection">
        <div class={"connection-ios__title"}>
          {window.translate.ios_instruction_title}
        </div>
        <div>
          <BasePlayer
            class={
              clientWidth.value > 700
                ? "connection-ios__image--450"
                : "connection-ios__image"
            }
            list={videoList}
          />
        </div>
        <div>{window.translate.ios_instruction_step_1}</div>
        <div>{window.translate.ios_instruction_step_2}</div>
        <div>{window.translate.ios_instruction_step_3}</div>
        <div>
          <img
            class={
              clientWidth.value > 700
                ? "connection-ios__image--450"
                : "connection-ios__image"
            }
            src={require("@/assets/images/connections/ios/01.png")}
            alt=""
          />
        </div>
      </div>
    );
  },
});

export { IosConnect };
