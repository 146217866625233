import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import { PopupError } from "@/components/popup/error/PopupError"
import { TheLogn } from "@/components/login/TheLogin"

const Auth = defineComponent({
  name: 'Auth',
  setup() {
    const route = useRoute()

    if (route.query.successful_payment === 'true') {
      window.ym(45609672,'reachGoal','landing_successful_payment') 
    }

    return () => (
      <section class={'wrapper center'}>
        <PopupError />
        <TheLogn />
      </section>
    )
  }
})

export { Auth }
