import { defineComponent, PropType, ref } from "vue";
import "./style.scss";

const NumberPicker = defineComponent({
  name: "NumberPicker",
  props: {
    numbers: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    active: {
      type: Number,
      default: 0,
    },
    onChange: Function as PropType<(num: number) => void>,
  },
  setup(props, { emit }) {
    return () => (
      <div style="margin: 0 30px 20px 30px;height: 92px;">
        <h1 class={"number-picker__title"}>{props.title}</h1>
        <div class={"number-picker"}>
          {props.numbers.length
            ? props.numbers.map((number, index) => (
                <div
                  class={
                    number === props.active
                      ? "cursor-pointer number-picker__number number-picker__number--active"
                      : "cursor-pointer number-picker__number"
                  }
                  onClick={() => {
                    emit("change", number);
                  }}
                >
                  {number}
                </div>
              ))
            : null}
        </div>
      </div>
    );
  },
});

export { NumberPicker };
