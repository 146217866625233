import { defineComponent, PropType, ref } from "vue";
import { BaseSearch } from "@/components/base/search/BaseSearch";
import { BasePagination } from "@/components/base/pagination";
import { BaseLoading } from "@/components/base/loading";
import "./style.scss";

export interface IColumn {
  label: string;
  field: string;
  style?: string;
  action?: (row: any, field: string, value: any) => any;
  copy?: boolean;
  counter?: boolean;
}

export interface IRow {
  [key: string]: any;
}

export interface IFilter {
  [key: string]: string;
}

export interface ISearch {
  filters: IFilter;
}

const BaseTable = defineComponent({
  name: "BaseTable",
  props: {
    notData: {
      type: String,
    },
    searchPlaceholder: {
      type: String,
    },
    buttonSearch: {
      type: Boolean,
      default: false,
    },
    searchActiveFilter: {
      type: String,
      default: "",
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    paginationPage: {
      type: Number,
      default: 1,
    },
    onPaginationActive: {
      type: Function as PropType<(active: number) => void>,
    },
    columns: {
      type: Array as PropType<IColumn[]>,
      required: true as true,
    },
    rows: {
      type: Array as PropType<IRow[]>,
      required: true as true,
    },
    search: {
      type: Object as PropType<ISearch>,
      default: null,
    },
    position: Array as PropType<number[]>,
    onRowClick: {
      type: Function as PropType<(row: any) => void>,
    },
    onFilter: {
      type: Function as PropType<(filter: string) => void>,
    },
    onSearch: {
      type: Function as PropType<(value: string) => void>,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    simpleSearch: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const table = ref();
    const copyBuff = (event: any) => {
      const text = event.target.innerText;
      const el = document.createElement("textarea");
      el.value = text;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      clearTimeout(parseInt(event.target.dataset.copyTimer));
      event.target.dataset.copyLabel = window.translate.copied;
      event.target.dataset.copyTimer = setTimeout(() => {
        event.target.dataset.copyLabel = window.translate.click_for_copying;
        event.target.dataset.copyTimer = 0;
      }, 2000);
    };

    const rowClick = (row: any) => {
      emit("RowClick", row);
    };
    const searchFilter = ref();
    if (props.search) {
      searchFilter.value = Object.keys(props.search.filters)[0];
    }
    const searchValue = ref();

    const simpleSearch = () => {
      if (props.simpleSearch && searchValue.value) {
        const trs = table.value.querySelectorAll(`tbody tr`);
        if (trs.length) {
          trs.forEach((tr: Element) => {
            tr.removeAttribute("data-hidden");
            const value = tr.querySelector(
              `td[data-field="${searchFilter.value}"] .table__value`
            );
            if (
              value?.innerHTML &&
              !value.innerHTML.includes(searchValue.value)
            ) {
              tr.setAttribute("data-hidden", "true");
            }
          });
        }
      } else if (props.simpleSearch && !searchValue.value) {
        const trs = table.value.querySelectorAll(`tbody tr`);
        if (trs.length) {
          trs.forEach((tr: Element) => {
            tr.removeAttribute("data-hidden");
          });
        }
      }
    };
    const changeFilter = (filter: string) => {
      searchFilter.value = filter;
      simpleSearch();
      emit("filter", filter);
    };
    const changeSearch = (value: string) => {
      searchValue.value = value;
      simpleSearch();
      emit("search", value);
    };
    return () => (
      <>
        {props.search && (
          <BaseSearch
            searchActiveFilter={props.searchActiveFilter}
            onFilter={(filter) => changeFilter(filter)}
            onSearch={(value) => changeSearch(value)}
            filters={props.search.filters}
            button={props.buttonSearch}
            placeholder={props.searchPlaceholder || window.translate.search}
          />
        )}
        <div style="overflow-x: auto;" class="hide-scroll">
          <table class={"table"} ref={table}>
            {props.rows.length ? (
              <thead>
                <tr>
                  {props.columns.map((column: IColumn) => (
                    <th style={column.style}>{column.label}</th>
                  ))}
                </tr>
              </thead>
            ) : (
              ""
            )}
            <tbody>
              {props.rows.length ? (
                props.reverse ? (
                  props.rows
                    .slice(0)
                    .reverse()
                    .map((row: IRow, index: number) => (
                      <tr onClick={() => rowClick(row)}>
                        {props.columns.map((column: IColumn) => (
                          <td class={column.style} data-field={column.field}>
                            <span
                              class="table__label"
                              data-label={column.label}
                            ></span>
                            <span class="table__value">
                              {column.counter ? (
                                index + 1
                              ) : column.copy ? (
                                <span
                                  class="table__copy"
                                  data-copy-label={window.translate.click_for_copying}
                                  data-copy-timer="0"
                                  onClick={(e) => copyBuff(e)}
                                >
                                  {row[column.field]}
                                </span>
                              ) : typeof column.action === "function" ? (
                                column.action(row, column.field, row[column.field])
                              ) : (
                                row[column.field]
                              )}
                            </span>
                          </td>
                        ))}
                      </tr>
                    ))
                ) : (
                  props.rows.map((row: IRow, index: number) => (
                    <tr onClick={() => rowClick(row)}>
                      {props.columns.map((column: IColumn) => (
                        <td class={column.style} data-field={column.field}>
                          <span
                            class="table__label"
                            data-label={column.label}
                          ></span>
                          <span class="table__value">
                            {column.counter ? (
                              index + 1
                            ) : column.copy ? (
                              <span
                                class="table__copy"
                                data-copy-label={window.translate.click_for_copying}
                                data-copy-timer="0"
                                onClick={(e) => copyBuff(e)}
                              >
                                {row[column.field]}
                              </span>
                            ) : typeof column.action === "function" ? (
                              column.action(row, column.field, row[column.field])
                            ) : (
                              row[column.field]
                            )}
                          </span>
                        </td>
                      ))}
                    </tr>
                  ))
                )
              ) : (
                <tr class="table__none-date">
                  <td
                    style="text-align: center; height: 160px; justify-content: center;"
                    colspan={props.columns.length + 1}
                  >
                    {props.loading ? <BaseLoading /> : <span>{props.notData || window.translate.no_data}</span>}
                  </td>
                </tr>
              )}
              {props.paginationPage > 2 && (
                <tr class="table__pagination">
                  <td colspan={props.columns.length + 1}>
                    <BasePagination
                      style="margin: 30px auto 0 auto;"
                      pages={props.paginationPage}
                      onChange={(active: number) =>
                        emit("paginationActive", active)
                      }
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  },
});

export { BaseTable };
