import { defineComponent } from "vue";

const IconLock = defineComponent({
  render() {
    return (
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 14.6599C12.8858 14.6599 11.9794 15.5664 11.9794 16.6805C11.9794 17.485 12.452 18.1809 13.134 18.5058V21.5984H14.866V18.5057C15.548 18.1808 16.0206 17.4849 16.0206 16.6805C16.0206 15.5664 15.1142 14.6599 14 14.6599Z"
          fill="currentColor"
        />
        <path
          d="M19.9175 10.0364V5.91753C19.9175 2.65457 17.263 0 14 0C10.737 0 8.08247 2.65457 8.08247 5.91753V10.0364C5.63245 11.8523 4.04124 14.7643 4.04124 18.0412C4.04124 23.5325 8.50871 28 14 28C19.4913 28 23.9588 23.5325 23.9588 18.0412C23.9588 14.7643 22.3676 11.8523 19.9175 10.0364ZM9.81443 5.91753C9.81443 3.60958 11.692 1.73196 14 1.73196C16.308 1.73196 18.1856 3.60958 18.1856 5.91753V9.00607C16.9123 8.41391 15.4942 8.08247 14 8.08247C12.5058 8.08247 11.0877 8.41391 9.81443 9.00613V5.91753ZM14 26.268C9.46371 26.268 5.7732 22.5775 5.7732 18.0412C5.7732 13.5049 9.46371 9.81443 14 9.81443C18.5363 9.81443 22.2268 13.5049 22.2268 18.0412C22.2268 22.5775 18.5363 26.268 14 26.268Z"
          fill="currentColor"
        />
      </svg>
    );
  },
});

export { IconLock };
