import { reactive } from 'vue';

export interface IState {
  isShowPayment: boolean;
}

const state = reactive<IState>({
  isShowPayment: false
})

export const payStore = {
  state,
};

