import { httpClient } from '@/http'

export interface ISubscription {
  active_from: string;
  active_until: string;
  id: number|null;
  renewable: number|null;
  status: string;
  tariff_plan: {
    alias: string;
    period_base: string;
    period_length: number|null;
    price: number|null;
  }
}

export interface IGetResponse {
  data: ISubscription[]
}
export interface IRequests {
  tariff_plan: string;
  use_bonus: string;
  use_balance: string;
  action: string;
}
export interface IPostResponse {
  data: {
    data: {
      price: number;
      price_after_calculation: number;
      bill_ct: string;
      bill_id: number;
      bill_status: string;
      message: string;
    }
  }
}
class SubscriptionsService {
  public get() {
    return httpClient.get<IGetResponse>('/subscriptions')
  }
  public post(tariff_plan: string, use_bonus: string, use_balance: string, action: string) {
    return httpClient.post<IRequests, IPostResponse>('/subscriptions', { tariff_plan, use_bonus, use_balance, action })
  }
}

export const subscriptionsService = new SubscriptionsService()
