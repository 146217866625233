import { defineComponent, ref, PropType } from "vue";
import "./style.scss";

const BaseLoading = defineComponent({
  name: "BaseLoading",
  setup() {
    return () => (
      <div class="base-loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  },
});

export { BaseLoading };
