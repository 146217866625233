import { defineComponent, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { searchStore } from "@/store/searchStore";
import {
  BaseTable,
  IColumn,
} from "@/components/base/table/BaseTable/BaseTable";
import { profileStore } from "@/store/profileStore";
import { BaseCard } from "@/components/base/card/BaseCard/BaseCard";
import { adminStore } from "@/store/adminStore";

const UsersCard = defineComponent({
  name: "UsersCard",
  setup() {
    const router = useRouter();
    const pending = ref(false);
    const columns = ref<IColumn[]>([]);
    const timerId = ref(0);

    const isButton = computed(() => {
      if (
        profileStore.state.balance.access_level &&
        profileStore.state.balance.access_level > 20
      ) {
        return false;
      } else {
        return true;
      }
    });

    const convertRenewal = (row: any) => {
      switch (row.renewal) {
        case 0:
          return "-";
        case 1:
          return "+";
        default:
          return row.renewal;
      }
    };
    const convertStatus = (row: any) => {
      switch (row.status) {
        case "expired":
          return "Истёк";
        case "upgraded":
          return "Улучшен";
        case "downgraded":
          return "Понижен";
        case "active":
          return "Активен";
        case "canceled":
          return "Отменён";
        case "paid":
          return "Оплачен";
        case "pending":
          return "В ожидании";
        case "deleted":
          return "Удалён";
        case "created":
          return "Создан";
        case "validated":
          return "Проверен";
        case "blocked":
          return "Заблокирован";
        case "timeout":
          return "Истёк";
        default:
          return row.status;
      }
    };
    const convertPaymentMethod = (row: any) => {
      switch (row.payment_method) {
        case "bank_card":
          return "Банковская карта";
        case "yookassa":
          return "Web";
        case "yookassa_mobile":
          return "Mobile";
        case "pay_from_account":
          return "Оплачено с баланса";
        default:
          return row.payment_method;
      }
    };

    const billColumns = [
      {
        label: "ID",
        field: "id",
      },
      {
        label: "Сумма",
        field: "amount",
      },
      {
        label: "BONUS",
        field: "amount_from_bonus",
      },
      {
        label: "RUB",
        field: "amount_from_rub",
      },
      {
        label: "Создано",
        field: "created_at",
      },
      {
        label: "Оплачено",
        field: "paid_at",
      },
      {
        label: "ПС",
        field: "payment_method",
        action: convertPaymentMethod,
      },
      {
        label: "Продление",
        field: "renewal",
        action: convertRenewal,
      },
      {
        label: "Статус",
        field: "status",
        action: convertStatus,
      },
    ];
    const cardColumns = [
      { label: "ID", field: "id" },
      {
        label: "Добавлена",
        field: "created_at",
      },
      {
        label: "ПС",
        field: "payment_method",
        action: convertPaymentMethod,
      },
      {
        label: "Месяц истечения срока действия",
        field: "expiry_month",
      },
      {
        label: "Год истечения срока действия",
        field: "expiry_year",
      },
      {
        label: "Начальные цифры",
        field: "first6",
      },
      { label: "Последние цифры", field: "last4" },
      {
        label: "Страна-эмитент",
        field: "issuer_country",
      },
      {
        label: "Статус",
        field: "status",
        action: convertStatus,
      },
    ];
    const userColumns = [
      { label: "ID", field: "id" },
      { label: "E-mail", field: "value" },
      {
        label: "Дата подтверждения",
        field: "confirmed_at",
      },
      {
        label: "Дата регистрации",
        field: "created_at",
      },
      {
        label: "IP регистрации",
        field: "created_from",
      },
    ];

    const search = { filters: adminStore.state.filters };

    const data = computed(() => {
      const users = adminStore.state.users;
      if (users?.length) {
        return users;
      }
      return [];
    });

    const openUser = (row: any) => {
      adminStore.state.users = [];
      searchStore.state.value = null;
      adminStore.state.filterValue = "";
      router.push(`/admin/user/${row.id}`);
    };
    const changeFilter = (filter: string) => {
      const value = adminStore.state.filterValue;
      adminStore.state.activePage = 1;
      adminStore.state.filterActive = filter;
      adminStore.state.meta = null;
      adminStore.state.user = null;
      columns.value = [];
      switch (filter) {
        case "bill":
          columns.value = billColumns;
          break;
        case "card":
          columns.value = cardColumns;
          break;
        case "ip":
          columns.value = userColumns;
          break;
        case "email":
          columns.value = userColumns;
          break;
      }
      if (value) {
        pending.value = true;
        adminStore.users().finally(() => (pending.value = false));
      }
    };
    const changeSearch = (value: string) => {
      adminStore.state.filterValue = value;
      clearTimeout(timerId.value);
      timerId.value = setTimeout(() => {
        adminStore.state.meta = null;
        adminStore.state.users = [];
        if (value) {
          pending.value = true;
          adminStore.users().finally(() => (pending.value = false));
        } else {
          pending.value = true;
          setTimeout(() => {
            pending.value = false;
          }, 600);
        }
      }, 600);
    };
    const meta = computed(() => {
      if (adminStore.state.meta) {
        return adminStore.state.meta;
      } else {
        return { last_page: 1 };
      }
    });
    const changeActivePagination = (active: number) => {
      adminStore.state.activePage = active;
      pending.value = true;
      adminStore.users().finally(() => (pending.value = false));
    };

    if (adminStore.state.filterActive) {
      changeFilter(adminStore.state.filterActive);
    } else {
      columns.value = userColumns;
    }

    adminStore.users();
    return () => (
      <BaseCard
        class="v-card--scroll"
        style="display: flex; flex-direction: column;max-width: 1010px;color: #fff; margin-right: 0;width: 100%;min-height: 234px;"
      >
        <BaseTable
          paginationPage={meta.value.last_page}
          onPaginationActive={(active: number) =>
            changeActivePagination(active)
          }
          onRowClick={(row) => openUser(row)}
          notData="Нет данных"
          buttonSearch={isButton.value}
          search={search}
          searchPlaceholder="Поиск..."
          searchActiveFilter={adminStore.state.filterActive}
          columns={columns.value}
          rows={data.value}
          onFilter={(filter) => changeFilter(filter)}
          onSearch={(value) => changeSearch(value)}
          loading={pending.value}
        />
      </BaseCard>
    );
  },
});

export { UsersCard };
