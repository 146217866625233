import { defineComponent, PropType } from "vue"
import "./style.scss"

const BaseLoader = defineComponent({
  name: "BaseLoader",
  props: {
    active: {
      type: Boolean,
      default: true
    },
  },
  setup(props, { emit }) {
    return () => props.active && (
    <div class="base-loader__wrapper">
      <img class="base-loader" src={require('@/assets/icons/loader.png')} alt="" />
    </div>
    )
  }
})

export { BaseLoader }
