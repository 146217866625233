import { defineComponent, PropType, ref, watch } from "vue";
import { BaseLoading } from "../../base/loading";
import { profileStore } from "@/store/profileStore";
import Cookies from "js-cookie";
import "./style.scss";

const ReconfirmationsPopup = defineComponent({
  name: "reconfirmation",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    onClose: Function as PropType<(value: number) => void>,
  },
  setup(props, { emit }) {
    const isReconfirmation = ref(true);
    const reconfirmationTime = Cookies.get("dvpn_reconfirmation_time");

    const isLoading = ref(true);
    const closeBackground = (e: any) => {
      if (!isLoading.value) {
        emit("close");
      }
    };
    const close = () => {
      if (!isLoading.value) {
        emit("close");
      }
    };
    watch(
      () => props.show,
      (first, second) => {
        if (first && isReconfirmation.value) {
          if (reconfirmationTime) {
            let date = new Date(reconfirmationTime);
            let currentDate = new Date();
            // если не прошло минуты
            if (date.getTime() + 60000 > currentDate.getTime()) {
              isReconfirmation.value = false;
              isLoading.value = false;
            } else {
              Cookies.set("dvpn_reconfirmation_time", new Date().toString());
              profileStore
                .emailReconfirmation()
                .then(() => {
                  isLoading.value = false;
                })
                .catch(() => {
                  isLoading.value = false;
                  isReconfirmation.value = false;
                });
            }
          } else {
            Cookies.set("dvpn_reconfirmation_time", new Date().toString());
            profileStore
              .emailReconfirmation()
              .then(() => {
                isLoading.value = false;
              })
              .catch(() => {
                isLoading.value = false;
                isReconfirmation.value = false;
              });
          }
        }
      }
    );
    return () =>
      props.show && (
        <div class="reconfirmation__wrapper">
          <div
            class="reconfirmation__background"
            onClick={closeBackground}
          ></div>
          <div class="reconfirmation">
            <div class="reconfirmation__content">
              <div class="reconfirmation__title">
                {window.translate.reconfirmation_title}
              </div>
              <div class="reconfirmation__description">
                {isLoading.value ? (
                  <BaseLoading />
                ) : (
                  <div class="reconfirmation__success">
                    {isReconfirmation.value
                      ? window.translate.reconfirmation_descrition
                      : window.translate.try_later}
                  </div>
                )}
              </div>
            </div>
            {!isLoading.value && (
              <div class="reconfirmation__control">
                <div class="reconfirmation__button--close" onClick={close}>
                  {window.translate.close}
                </div>
              </div>
            )}
          </div>
        </div>
      );
  },
});

export { ReconfirmationsPopup };
