import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { Auth } from "@/pages/Auth";
import { DashBoard } from "@/pages/DashBoard";
import { authStore } from "@/store/authStore";
import { Overview } from "@/pages/overview";
import { CompanyList, CompanyCreate, CompanyUpdate } from "@/pages/company";
import { Tariff } from "@/pages/Tariff";
import { TheCards } from "@/pages/cards/TheCards";
import { TheConnection } from "@/pages/TheConnection";
import { Windows10Connect } from "@/pages/connect/windows10/Windows10";
import { Windows7Connect } from "@/pages/connect/windows7/Windows7";
import { AndroidConnect } from "@/pages/connect/android/Android";
import { OpenVPNConnect } from "@/pages/connect/openvpn/OpenVPN";
import { AndroidApp } from "@/pages/app/android";
import { MacConnect } from "@/pages/connect/macos/Mac";
import { IosConnect } from "@/pages/connect/ios/IOS";
import { Confirmation } from "@/pages/Confirmation/Confirmation";
import { PageNotFound } from "@/pages/error/PageNotFound";
import { ThePayExtention } from "@/pages/ThePayExtention/ThePayExtetion";
import { TheBonuses } from "@/pages/TheBonuses/TheBonuses";
import { Users } from "@/pages/admin/users";
import { User } from "@/pages/admin/user";
import { confirmEmailStore } from "@/store/ConfirmEmail";
import { profileStore } from "@/store/profileStore";
import { routerStore } from "@/store/routerStore";
import { IosApp } from "@/pages/app/ios";
import { AppGalleryApp } from "@/pages/app/appgallery";
import Cookies from "js-cookie";

const isNotAuth = (to: any, from: any, next: any) => {
  const dynamicToken = Cookies.get("dvpn_auth_token");
  const staticToken = Cookies.get("dvpn_auth_token_static");

  if (dynamicToken && staticToken && dynamicToken === staticToken) {
    authStore.state.token = dynamicToken;
    next("/overview");
  } else {
    next();
  }
};

const ifAuthenticated = (to: any, from: any, next: any) => {
  const dynamicToken = Cookies.get("dvpn_auth_token");
  const staticToken = Cookies.get("dvpn_auth_token_static");

  if (to.path && to.path.includes("admin")) {
    if (!profileStore.state.isCheckBalance) {
      profileStore.getBalance().then(() => {
        if (dynamicToken || staticToken || authStore.state.token) {
          const meta = document.querySelector('meta[name~="apple-itunes-app"]');
          if (meta) {
            meta.setAttribute("content", "app-id=1628019423");
          }
          next();
          return;
        } else {
          next("/auth");
        }
      });
    }
  } else {
    if (!profileStore.state.isCheckBalance) {
      profileStore.getBalance();
    }
    if (dynamicToken || staticToken || authStore.state.token) {
      const meta = document.querySelector('meta[name~="apple-itunes-app"]');
      if (meta) {
        meta.setAttribute("content", "app-id=1628019423");
      }
      next();
      return;
    } else {
      next("/auth");
    }
  }
};

var timer_id = 0;

const checkOnline = () => {
  clearTimeout(timer_id);
  profileStore.online();
  setTimeout(checkOnline, 3600000); // 60m
};

const checkConfirmEmail = (to: any, from: any, next: any) => {
  routerStore.clearState();
  routerStore.state.name = to.meta.name;
  routerStore.state.download = to.meta.download;
  routerStore.state.link_app = to.meta.link_app;

  confirmEmailStore.state.isView = !profileStore.state.balance.email_confirmed;
  checkOnline();

  if (!profileStore.state.isCheckBalance) {
    profileStore.getBalance();
  }
  next();
};

const routes: Array<RouteRecordRaw> = [
  { path: "/:pathMatch(.*)*", redirect: { path: "overview" } },
  { path: "/", redirect: { path: "overview" } },
  {
    path: "/auth",
    name: "Auth",
    component: Auth,
    beforeEnter: isNotAuth,
  },
  {
    path: "/pay/:token",
    name: "Pay",
    component: ThePayExtention,
  },
  {
    path: "/profile/confirmation",
    name: "Confirmation",
    component: Confirmation,
  },
  {
    path: "/",
    name: "DashBoard",
    component: DashBoard,
    children: [
      {
        path: "404",
        name: "404",
        meta: {
          name: window.translate.page_not_found,
          download: false,
          selected: "",
        },
        components: {
          content: PageNotFound,
        },
      },
      {
        path: "company/list",
        name: "company-list",
        meta: {
          name: "Компании",
          download: false,
          selected: "Компании",
        },
        components: {
          content: CompanyList,
        },
        beforeEnter: checkConfirmEmail,
      },
      {
        path: "company/create",
        name: "company-create",
        meta: {
          name: "Добавление компании",
          download: false,
          selected: "Компании",
        },
        components: {
          content: CompanyCreate,
        },
        beforeEnter: checkConfirmEmail,
      },
      {
        path: "company/:companyId/update",
        name: "company-update",
        meta: {
          name: "Редактирование компании",
          download: false,
          selected: "Компании",
        },
        components: {
          content: CompanyUpdate,
        },
        beforeEnter: checkConfirmEmail,
      },
      {
        path: "overview",
        meta: {
          name: window.translate.dashboard,
          download: false,
          selected: window.translate.dashboard,
        },
        components: {
          content: Overview,
        },
        beforeEnter: checkConfirmEmail,
      },
      {
        path: "bonuses",
        meta: {
          name: window.translate.bonuses,
          download: false,
          selected: window.translate.dashboard,
        },
        components: {
          content: TheBonuses,
        },
        beforeEnter: checkConfirmEmail,
      },
      {
        path: "tariff",
        meta: {
          name: window.translate.tariff_change_history,
          download: false,
          selected: window.translate.dashboard,
        },
        components: {
          content: Tariff,
        },
        beforeEnter: checkConfirmEmail,
      },
      {
        path: "cards",
        meta: {
          name: window.translate.cards,
          download: false,
          selected: window.translate.dashboard,
        },
        components: {
          content: TheCards,
        },
        beforeEnter: checkConfirmEmail,
      },
      {
        path: "connection",
        meta: {
          name: window.translate.connection,
          download: false,
          selected: window.translate.dashboard,
        },
        components: {
          content: TheConnection,
        },
        beforeEnter: checkConfirmEmail,
      },
      {
        path: "connection/windows10",
        meta: {
          name: "Windows 10",
          download: false,
          selected: window.translate.instruction,
        },
        components: {
          content: Windows10Connect,
        },
        beforeEnter: checkConfirmEmail,
      },
      {
        path: "connection/windows7",
        meta: {
          name: "Windows 7",
          download: false,
          selected: window.translate.instruction,
        },
        components: {
          content: Windows7Connect,
        },
        beforeEnter: checkConfirmEmail,
      },
      {
        path: "connection/openvpn",
        meta: {
          name: "OpenVPN",
          download: false,
          selected: window.translate.instruction,
        },
        components: {
          content: OpenVPNConnect,
        },
        beforeEnter: checkConfirmEmail,
      },
      {
        path: "connection/macos",
        meta: {
          name: "MacOS",
          download: false,
          selected: window.translate.instruction,
        },
        components: {
          content: MacConnect,
        },
        beforeEnter: checkConfirmEmail,
      },
      {
        path: "connection/ios",
        meta: {
          name: "iOS",
          download: true,
          link_app:
            "https://apps.apple.com/ru/app/dubkivpn-fast-secure-vpn/id1628019423",
          selected: window.translate.instruction,
        },
        components: {
          content: IosConnect,
        },
        beforeEnter: checkConfirmEmail,
      },
      {
        path: "app/ios",
        meta: {
          name: "iOS",
          download: true,
          link_app:
            "https://apps.apple.com/ru/app/dubkivpn-fast-secure-vpn/id1628019423",
          selected: window.translate.applications,
        },
        components: {
          content: IosApp,
        },
        beforeEnter: checkConfirmEmail,
      },
      {
        path: "app/app-gallery",
        meta: {
          name: "AppGallery",
          download: true,
          link_app: "https://appgallery.huawei.com/#/app/C107900651",
          selected: window.translate.applications,
        },
        components: {
          content: AppGalleryApp,
        },
        beforeEnter: checkConfirmEmail,
      },
      {
        path: "connection/android",
        meta: {
          name: "Android",
          download: true,
          link_app:
            "https://play.google.com/store/apps/details?id=ru.dubki.vpn",
          selected: window.translate.instruction,
        },
        components: {
          content: AndroidConnect,
        },
        beforeEnter: checkConfirmEmail,
      },
      {
        path: "app/android",
        meta: {
          name: "Android",
          download: true,
          link_app:
            "https://play.google.com/store/apps/details?id=ru.dubki.vpn",
          selected: window.translate.applications,
        },
        components: {
          content: AndroidApp,
        },
        beforeEnter: checkConfirmEmail,
      },
      {
        path: "admin/users",
        meta: {
          name: "Пользователи",
          download: false,
          link_app: "",
          selected: "Управление",
        },
        components: {
          content: Users,
        },
        beforeEnter: checkConfirmEmail,
      },
      {
        path: "admin/user/:id",
        meta: {
          name: "Пользователь" + " #{id}",
          download: false,
          link_app: "",
          selected: "Управление",
        },
        components: {
          content: User,
        },
        beforeEnter: checkConfirmEmail,
      },
      // {
      //   path: "profile",
      //   meta: {
      //     name: "Профиль",
      //     download: false,
      //     link_app: "",
      //     selected: "Профиль",
      //   },
      //   components: {
      //     content: Profile,
      //   },
      //   beforeEnter: checkConfirmEmail,
      // },
    ],
    beforeEnter: ifAuthenticated,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
