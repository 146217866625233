import { defineComponent } from "vue";
import { profileStore } from "@/store/profileStore";
import { BaseCard } from "@/components/base/card/BaseCard/BaseCard";
import "./style.scss";

const TheBalanceCard = defineComponent({
  name: "TheBalanceCard",
  setup() {
    profileStore.getBalance()
    return () => (
      <BaseCard>
        <div class={"balance-card"}>
          <div style={"margin-bottom: 10px;"} class={""}>
            <div class={"balance-card__label"}>{window.translate.balance}</div>
            <div class={"balance-card__value"}>
              {profileStore.state.balance.real_money}
            </div>
          </div>
          <div class={""}>
            <div class={"balance-card__label"}>{window.translate.bonus_balance}</div>
            <div class={"balance-card__value"}>
              {profileStore.state.balance.bonus_money}
            </div>
          </div>
        </div>
      </BaseCard>
    )
  },
});

export { TheBalanceCard };
