import { defineComponent, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { TheBalanceCard } from "@/components/balanceCard/TheBalanceCard";
import { AuthDataCard } from "@/components/authDataCard/TheAuthDataCard";
import { HelpCard } from "@/components/helpCard/HelpCard";
import { TariffCard } from "@/components/tariffCard/TariffCard";
import { ServersCard } from "@/components/serversCard/ServersCard";
import { profileStore } from "@/store/profileStore";
import { notificationStore } from "@/store/notificationStore";
import { subscriptionsStore } from "@/store/subscriptionsStore";
import { authStore } from "@/store/authStore";
import Cookies from "js-cookie";
import { BaseLoading } from "@/components/base/loading";
import { serversStore } from "@/store/serversStore";
import "./style.scss";

const Overview = defineComponent({
  name: "Overview",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const auth_load = ref(true);
    const tariff_load = ref(true);
    const servers_load = ref(true);

    profileStore.getConnection().finally(() => {
      auth_load.value = false;
    });

    subscriptionsStore.getSubscriptions().finally(() => {
      tariff_load.value = false;
    });

    serversStore.getServers().finally(() => {
      servers_load.value = false;
    });

    // сообщение о блокировке
    if (
      !authStore.state.trial &&
      !Cookies.get("is_view_trial_blocking:" + Cookies.get("dvpn_auth_email"))
    ) {
      notificationStore.state.notification.message =
        window.translate.trial_blocking;
      notificationStore.state.notification.view = true;
      Cookies.set("is_view_trial_blocking:" + Cookies.get("dvpn_auth_email"), "no", {
        expires: 7,
      });
    }

    const isTrial = computed(() => {
      if (
        subscriptionsStore.state.active_subscription.status !== "expired" &&
        subscriptionsStore.state.active_subscription.tariff_plan.alias ===
          "trial"
      ) {
        return true;
      } else {
        return false;
      }
    });

    if (route.query.successful_payment === "true") {
      notificationStore.state.notification.message =
        window.translate.successful_payment;
      notificationStore.state.notification.view = true;
      const name = localStorage.getItem("cart_name");
      const price = localStorage.getItem("cart_price");
      const variant = localStorage.getItem("cart_variant");
      const payment_id = localStorage.getItem("payment_id");

      window.ym(45609672, "reachGoal", " successful_payment");
      window.dataLayer.push({
        ecommerce: {
          currencyCode: "RUB",
          purchase: {
            actionField: {
              id: payment_id,
            },
            products: [
              {
                name: name,
                price: price,
                variant: variant,
                quantity: 1,
              },
            ],
          },
        },
      });
      router.push("/overview");
    }

    const real_money = computed(() => {
      if (
        profileStore.state.balance.real_money !== null &&
        profileStore.state.balance.real_money !== undefined
      ) {
        return profileStore.state.balance.real_money;
      }
      return 0;
    });
    const bonus_money = computed(() => {
      if (
        profileStore.state.balance.bonus_money !== null &&
        profileStore.state.balance.bonus_money !== undefined
      ) {
        return profileStore.state.balance.bonus_money;
      }
      return 0;
    });
    return () => (
      <div class="row-box" style="flex-wrap: wrap;">
        <div class="app-buttons">
          <a
            href="https://play.google.com/store/apps/details?id=ru.dubki.vpn"
            target="__blank"
          >
            <img
              src={require("@/assets/images/button-app/android.png")}
              alt=""
            />
          </a>
          <a
            href="https://apps.apple.com/ru/app/dubkivpn-fast-secure-vpn/id1628019423"
            target="__blank"
          >
            <img src={require("@/assets/images/button-app/ios.png")} alt="" />
          </a>
          <a
            href="https://appgallery.huawei.com/#/app/C107900651"
            target="__blank"
          >
            <img src={require("@/assets/images/button-app/appgallery.png")} alt="" />
          </a>
        </div>
        {(auth_load.value || tariff_load.value || servers_load.value) && (
          <div class="overview__loader">
            <BaseLoading />
          </div>
        )}
        {!auth_load.value &&
          !tariff_load.value &&
          !servers_load.value &&
          real_money.value + bonus_money.value > 0 && (
            <TheBalanceCard class="gradient" />
          )}
        {!auth_load.value &&
          !tariff_load.value &&
          !servers_load.value &&
          isTrial.value && <HelpCard class="gradient" />}
        {!auth_load.value && !tariff_load.value && !servers_load.value && (
          <AuthDataCard
            class={
              real_money.value + bonus_money.value === 0 &&
              !isTrial.value &&
              "gradient"
            }
          />
        )}
        {!auth_load.value && !tariff_load.value && !servers_load.value && (
          <TariffCard />
        )}
        {!auth_load.value && !tariff_load.value && !servers_load.value && (
          <ServersCard />
        )}
      </div>
    );
  },
});

export { Overview };
