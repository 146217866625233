import { defineComponent, ref, onMounted } from "vue";
import { BasePlayer } from "@/components/base/player/BasePlayer";
import "./style.scss";

const Windows10Connect = defineComponent({
  name: "Windows10Connect",
  setup() {
    const clientWidth = ref(document.documentElement.clientWidth);
    const url = process.env.VUE_APP_INSTRUCTION_URL;
    onMounted(() => {
      window.addEventListener("resize", () => {
        clientWidth.value = document.documentElement.clientWidth;
      });
    });
    const videoList = [
      {
        name: window.translate.native,
        poster: require("@/assets/images/connections/windows/preview/native.webp"),
        list: [
          `${url}/tutorials/native/windows-10.webm`,
          `${url}/tutorials/native/windows-10.mp4`,
          `${url}/tutorials/native/windows-10.ogg`,
        ],
      },
      {
        name: "OpenVPN",
        poster: require("@/assets/images/connections/windows/preview/openvpn.webp"),
        list: [
          `${url}/tutorials/openvpn/windows-10.webm`,
          `${url}/tutorials/openvpn/windows-10.mp4`,
          `${url}/tutorials/openvpn/windows-10.ogg`,
        ],
      },
    ];
    return () => (
      <div class="connection">
        <div class={"connection-windows__title"}>
          {window.translate.windows_10_instruction_title}
        </div>
        <BasePlayer
          class={
            clientWidth.value > 1200
              ? "connection-windows__image--default"
              : "connection-windows__image"
          }
          list={videoList}
        />
        <div>{window.translate.windows_10_instruction_step_1}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/windows/01.jpg")}
            alt=""
          />
        </div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/windows/02.jpg")}
            alt=""
          />
        </div>
        <div>{window.translate.windows_10_instruction_step_2}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--900"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/windows/03.jpg")}
            alt=""
          />
        </div>
        <div>{window.translate.windows_10_instruction_step_3} </div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--900"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/windows/04.jpg")}
            alt=""
          />
        </div>
        <div>{window.translate.windows_10_instruction_step_4}</div>
        <ul class="connection-windows__list">
          <li>{window.translate.windows_10_instruction_step_4_1}</li>
          <li>{window.translate.windows_10_instruction_step_4_2}</li>
          <li>{window.translate.windows_10_instruction_step_4_3}</li>
          <li>{window.translate.windows_10_instruction_step_4_4}</li>
        </ul>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--900"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/windows/05.png")}
            alt=""
          />
        </div>
        <div>{window.translate.windows_10_instruction_step_5}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--900"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/windows/06.jpg")}
            alt=""
          />
        </div>
        <div>{window.translate.windows_10_instruction_step_6} </div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--900"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/windows/under-protect.jpg")}
            alt=""
          />
        </div>
        <div>{window.translate.windows_10_instruction_step_7}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/windows/08.jpg")}
            alt=""
          />
        </div>
      </div>
    );
  },
});

export { Windows10Connect };
