import { defineComponent, ref } from "vue";
import { IServer } from "@/services/serversService";
import { serversStore } from "@/store/serversStore";
import { AuthDataCard } from "@/components/authDataCard/TheAuthDataCard";
import { ServersCard } from "@/components/serversCard/ServersCard";

const data = ref<IServer[]>([]);

const TheConnection = defineComponent({
  name: "TheConnection",
  setup() {
    serversStore.getServers().then(() => {
      if (serversStore.state.servers.length) {
        data.value = serversStore.state.servers;
      }
    });
    return () => (
      <div class={"connection row-box"} style={"flex-wrap: wrap;"}>
        <AuthDataCard />
        <ServersCard />
      </div>
    );
  },
});

export { TheConnection };
