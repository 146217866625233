import { defineComponent } from "vue";
import { notificationStore } from "@/store/notificationStore";
import "./style.scss";

const Notification = defineComponent({
  name: "Notification",
  setup() {
    const html = () => notificationStore.state.notification.view && (
      <div class="notification__background" onClick={closeBg}>
        <div class="notification">
          <div class="notification__content">
            <div class="notification__title">{window.translate.notification}</div>
            <div class="notification__description" v-html={notificationStore.state.notification.message}>
            </div>
          </div>
          <div onClick={close} class="notification__control">
            <div class="notification__button"><span>{window.translate.close}</span></div>
          </div>
        </div>
      </div>
    );
    const closeBg = (event: any) => {
      if (event.target.className === 'notification__background') {
        notificationStore.state.notification.view = false
        notificationStore.state.notification.message = null
      }
    }
    const close = () => {
      notificationStore.state.notification.view = false
      notificationStore.state.notification.message = null
    }
    return html
  },
});

export { Notification };
