import { httpClient } from "@/http";

export interface ICreateData {
  name: string;
  company_domain_name: string;
  domain_auth: boolean;
}

export interface IUpdateData {
  id: number | string;
  data: {
    name: string;
    company_domain_name: string;
    domain_auth: boolean;
  };
}

const actions = {
  clientGet(id: number) {
    return new Promise((resolve, reject) => {
      if (id) {
        httpClient
          .get(`clients/business/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject("id - undefined");
      }
    });
  },
  clientCreate(data: ICreateData) {
    return new Promise((resolve, reject) => {
      httpClient
        .post("clients/business", data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  clientUpdate(data: IUpdateData) {
    return new Promise((resolve, reject) => {
      httpClient
        .patch(`clients/business/${data.id}`, data.data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  clientRemove(id: number | string) {
    return new Promise((resolve, reject) => {
      httpClient
        .delete(`clients/business/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  clientList() {
    return new Promise((resolve, reject) => {
      httpClient
        .get("clients/business")
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export const businessService = {
  ...actions,
};
