import { httpClient } from "@/http";

export interface IData {
  data: {
    client_id: number;
    auth_token?: string;
    is_new_user?: boolean;
    trial?: boolean;
    identifier_type?: string;
    session_id?: number;
  };
}
export interface IAuthResponse {
  data: IData;
}
export interface IAuthRequests {
  type: string;
  value: string;
}
export interface IVerificationCodeRequests {
  code: string;
}

export interface IVerificationCodeResponse {
  data: {
    status_code: number;
    status_message: string;
    data: {
      client_id: number;
      auth_token: string;
      is_new_user: boolean;
    };
  };
}

class AuthService {
  public login(type: string, value: string) {
    return httpClient.post<IAuthRequests, IAuthResponse>("/auth", {
      type,
      value,
    });
  }
  public verificationCode(sesion_id: number, code: string) {
    return httpClient.patch<
      IVerificationCodeRequests,
      IVerificationCodeResponse
    >(`/auth/${sesion_id}`, { code });
  }
}

export const authService = new AuthService();
