import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { validationsStore } from "@/store/validationsStore";
import { errorStore } from "@/store/errorStore";
import "./style.scss";

const Confirmation = defineComponent({
  name: "Confirmation",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const message = ref("");
    if (route.query.session_id?.toString() && route.query.code?.toString()) {
      const session_id = route.query.session_id?.toString();
      const code = route.query.code?.toString();

      validationsStore.state.session_id = session_id.toString();
      validationsStore.state.code = code.toString();
      validationsStore
        .identifiers()
        .then(() => {
          message.value = window.translate.email_confirmed;
          setTimeout(() => {
            router.push("/overview");
          }, 1000);
        })
        .catch((e) => {
          message.value = window.translate.failed_verify_email;
          setTimeout(() => {
            router.push("/overview");
          }, 1000);
          console.warn("Ошибка в identifiers()");
          errorStore.treatment("identifiers", e);
        });
    }
    return () => (
      <section class={"wrapper center confirmation-message"}>
        {message.value}
      </section>
    );
  },
});

export { Confirmation };
