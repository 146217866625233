import { reactive } from 'vue'

const state = reactive({
  name: '',
  download: false,
  link_app: undefined
})

const clearState = () => {
  state.name = ''
  state.download = false
  state.link_app = undefined
}

export const routerStore = {
  state,
  clearState
};

