import { defineComponent, ref, onMounted } from "vue";
import "./style.scss";

const AndroidConnect = defineComponent({
  name: "TheAndroidApp",
  setup() {
    const clientWidth = ref(document.documentElement.clientWidth);

    onMounted(() => {
      window.addEventListener("resize", () => {
        clientWidth.value = document.documentElement.clientWidth;
      });
    });
    return () => (
      <div class="connection">
        <div class={"connection-android__title"}>
          {window.translate.android_instruction_title}
        </div>
        <div>{window.translate.android_instruction_step_1}</div>
        <div>
          <img
            class={
              clientWidth.value > 700
                ? "connection-android__image--450"
                : "connection-android__image"
            }
            src={require("@/assets/images/connections/android/01.jpg")}
            alt=""
          />
        </div>
        <div>{window.translate.android_instruction_step_2} </div>
        <div>
          <img
            class={
              clientWidth.value > 700
                ? "connection-android__image--450"
                : "connection-android__image"
            }
            src={require("@/assets/images/connections/android/02.jpg")}
            alt=""
          />
        </div>
        <div>{window.translate.android_instruction_step_3} </div>
        <div>{window.translate.android_instruction_step_4}</div>
        <div>
          <img
            class={
              clientWidth.value > 700
                ? "connection-android__image--450"
                : "connection-android__image"
            }
            src={require("@/assets/images/connections/android/03.jpg")}
            alt=""
          />
        </div>
        <div>{window.translate.android_instruction_step_5}</div>
        <div>
          <img
            class={
              clientWidth.value > 700
                ? "connection-android__image--450"
                : "connection-android__image"
            }
            src={require("@/assets/images/connections/android/04.jpg")}
            alt=""
          />
        </div>
      </div>
    );
  },
});

export { AndroidConnect };
