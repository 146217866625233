import { defineComponent } from "vue"
import { errorStore } from "@/store/errorStore"
import { IconNotification } from "@/assets/icons/IconNotification"
import "./style.scss"

const PopupError = defineComponent({
  name: "PopupError",
  setup() {
    const message = errorStore.error_popup
    const closePopup = () => { errorStore.cleanErrorPopup() }
    return () =>
      !!message.value &&
      (<div class="error-popup">
        <div class="error-popup__content">
          <div class="error-popup__icon"><IconNotification /></div>
          <div class="error-popup__message">{message.value}</div>
        </div>
        <div class="error-popup__bottom">
          <div class="error-popup__button" onClick={closePopup}><span>{window.translate.close}</span></div>
        </div>
      </div>)
  }
})

export { PopupError }
