import { defineComponent, ref } from "vue";
import { promocodeStore } from "@/store/promocodeStore";
import "./style.scss";

const Promocodes = defineComponent({
  name: "promocode",
  setup() {
    const promocode = ref();
    const handleEnter = function (event: any) {
      if (event.keyCode === 13) {
        event.preventDefault();
        handlePromocode();
      }
    };
    const html = () =>
      promocodeStore.state.isPromocode && (
        <div class="promocode__background" onClick={closeBackground}>
          <div class="promocode">
            <div class="promocode__content">
              <div class="promocode__title">
                {window.translate.promocode}
              </div>
              <div class="promocode__description">
                {promocodeStore.state.mode ? (
                  <div class="promocode__success">
                    {promocodeStore.state.mode === "plan_upgrade"
                      ? window.translate.tariff_plan_upgrade_notification
                      : window.translate.promo_code_activation_notification}
                  </div>
                ) : (
                  <input
                    ref={promocode}
                    onKeyup={handleEnter}
                    class="promocode__input"
                    type="text"
                    placeholder={window.translate.enter_promo_code}
                  />
                )}
                <div class="promocode__error">{promocodeStore.state.error}</div>
              </div>
            </div>
            <div class="promocode__control">
              <div class="promocode__button--close" onClick={close}>
                {promocodeStore.state.mode
                  ? window.translate.thanks
                  : window.translate.close}
              </div>
              {!promocodeStore.state.mode && (
                <div
                  class="promocode__button--handle"
                  onClick={handlePromocode}
                >
                  {window.translate.activate}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    const handlePromocode = () => {
      promocodeStore.state.error = null;
      promocodeStore.state.mode = null;
      if (promocode.value.value) {
        promocodeStore.promocode(promocode.value.value);
      }
    };
    const closeBackground = (e: any) => {
      const isNotClose = e.target.className !== "promocode__background";
      if (isNotClose) {
        return;
      }
      promocodeStore.state.isPromocode = false;
      promocodeStore.state.error = null;
      if (promocodeStore.state.mode) {
        promocodeStore.state.mode = null;
        location.href = "/overview";
      }
    };
    const close = () => {
      promocodeStore.state.isPromocode = false;
      promocodeStore.state.error = null;
      if (promocodeStore.state.mode) {
        promocodeStore.state.mode = null;
        location.href = "/overview";
      }
    };
    return html;
  },
});

export { Promocodes };
