import { defineComponent } from "vue"
import "./style.scss"

const PageNotFound = defineComponent({
  name: "PageNotFound",
  setup() {
    return () => (
      <div class={'error-page'}>
        <div class={"error-page__404"}>404</div>
      </div>
    )
  }
})

export { PageNotFound }
