import { defineComponent } from "vue";

const IconNotification = defineComponent({
  render() {
    return (
      <svg width="76" height="85" viewBox="0 0 76 85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M48 74C48 78.9706 43.7467 83 38.5 83C33.2533 83 29 78.9706 29 74M37.9282 2C40.1855 2 44.5359 3.65521 44.4413 10.173C44.4356 10.5637 44.6539 10.9315 45.009 11.1082C55.7661 16.462 60.9077 21.2027 64.9446 30.051C66.3578 33.1484 66.8835 36.5515 66.8835 39.945V55.8647C66.8835 56.1984 66.9253 56.5286 67.0156 56.8504C67.9328 60.1207 69.9688 65.7808 72.4027 68.4257L72.418 68.4423C73.22 69.3137 74.1087 70.2794 73.9891 71.446C73.8572 72.7324 73.2127 74 71.3766 74H4.62344C2.78734 74 2.14277 72.7324 2.01088 71.446C1.89128 70.2794 2.78 69.3137 3.58202 68.4423L3.5973 68.4257C6.03125 65.7808 8.06724 60.1207 8.98444 56.8504C9.07468 56.5286 9.1165 56.1984 9.1165 55.8647V39.945C9.1165 36.5515 9.64223 33.1484 11.0554 30.051C15.0923 21.2027 20.2339 16.462 30.991 11.1082C31.3461 10.9315 31.5645 10.5637 31.5583 10.173C31.4553 3.65521 35.671 2 37.9282 2Z" stroke="currentColor" stroke-width="3"/>
      </svg>
    );
  },
});

export { IconNotification };
