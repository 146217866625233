import { defineComponent, ref, onMounted } from "vue";
import { BasePlayer } from "@/components/base/player/BasePlayer";
import "./style.scss";

const Windows7Connect = defineComponent({
  name: "Windows7Connect",
  setup() {
    const clientWidth = ref(document.documentElement.clientWidth);
    const url = process.env.VUE_APP_INSTRUCTION_URL;
    onMounted(() => {
      window.addEventListener("resize", () => {
        clientWidth.value = document.documentElement.clientWidth;
      });
    });

    const videoList = [
      {
        name: window.translate.native,
        poster: require("@/assets/images/connections/windows/preview/native.webp"),
        list: [
          `${url}/tutorials/native/windows-7.webm`,
          `${url}/tutorials/native/windows-7.mp4`,
          `${url}/tutorials/native/windows-7.ogg`,
        ],
      },
    ];

    return () => (
      <div class="connection">
        <div class={"connection-windows__title"}>
          {window.translate.windows_7_instruction_title}
        </div>
        <BasePlayer
          class={
            clientWidth.value > 1200
              ? "connection-windows__image--default"
              : "connection-windows__image"
          }
          list={videoList}
        />
        <div>{window.translate.windows_7_instruction_step_1}</div>
        <div>{window.translate.windows_7_instruction_step_2}</div>
        <div>{window.translate.windows_7_instruction_step_3}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/windows7/1.png")}
            alt=""
          />
        </div>
        <div>{window.translate.windows_7_instruction_step_4}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/windows7/2.png")}
            alt=""
          />
        </div>
        <div>{window.translate.windows_7_instruction_step_5}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/windows7/3.png")}
            alt=""
          />
        </div>
        <div>{window.translate.windows_7_instruction_step_6}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/windows7/4.png")}
            alt=""
          />
        </div>
        <div>{window.translate.windows_7_instruction_step_7}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/windows7/5.png")}
            alt=""
          />
        </div>
        <div>{window.translate.windows_7_instruction_step_8}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/windows7/6.png")}
            alt=""
          />
        </div>
        <div>{window.translate.windows_7_instruction_step_9}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/windows7/7.png")}
            alt=""
          />
        </div>
        <div>{window.translate.windows_7_instruction_step_10}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/windows7/8.png")}
            alt=""
          />
        </div>
        <div>{window.translate.windows_7_instruction_step_11}</div>
      </div>
    );
  },
});

export { Windows7Connect };
