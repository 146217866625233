import { reactive } from "vue";

interface IState {
  filter: string|null;
  value: string|null;
  timerId: number;
}

const state = reactive<IState>({
  filter: null,
  value: null,
  timerId: 0,
});

export const searchStore = {
  state,
};
