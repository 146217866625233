import { reactive } from "vue";
import { authService } from "@/services/authService";
import { errorStore } from "./errorStore";
import { notificationStore } from "./notificationStore";
import Cookies from "js-cookie";

export interface IState {
  email: string | null;
  token: string | null;
  // dvpn_token: string|null;
  session_id: number | null;
  is_new_user: boolean;
  trial: boolean;
  domain: string;
  form: {
    type: string;
    value: string;
  };
}

const state = reactive<IState>({
  email: null,
  token: null,
  session_id: null,
  // dvpn_token: null,
  is_new_user: false,
  trial: true,
  domain:
    process.env.VUE_APP_MODE === "0"
      ? "domain.com"
      : process.env.VUE_APP_MODE === "1"
      ? "dl-net.ru"
      : "dubki-vpn.ru",
  form: {
    type: "email",
    value: "",
  },
});

const actions = {
  async login() {
    const {
      form: { type, value },
    } = state;
    const { data } = await authService.login(type, value);
    if (data?.data?.auth_token) {
      setToken(data.data.auth_token);
    }
    if (data.data.session_id) {
      state.session_id = data.data.session_id;
    }
    if (data.data.is_new_user) {
      state.is_new_user = data.data.is_new_user;
      Cookies.set("is_new_user", state.is_new_user.toString(), {
        expires: 365,
      });
      window.ym(45609672, "reachGoal", "is_new_user");
    }
    if (data.data.trial === false) {
      state.trial = data.data.trial;
    }
    setEmail(value);
    return data;
  },
  async verificationCode(code: string) {
    if (state.session_id) {
      const { data } = await authService.verificationCode(
        state.session_id,
        code
      );
      if (data.data.auth_token) {
        setToken(data.data.auth_token);
      }
      Cookies.remove("is_new_user");
      return data;
    }
  },
  async logoutAuth() {
    try {
      state.token = null;
      state.email = null;
      deleteToken();
      location.href = "/auth";
    } catch (e) {
      errorStore.treatment("logout", e);
    }
  },
  async logout() {
    try {
      console.log("logout");
      state.token = null;
      state.email = null;
      deleteToken();
    } catch (e) {
      errorStore.treatment("logout", e);
    }
  },
};

const setToken = (token: string) => {
  try {
    state.token = token;
    Cookies.set("dvpn_auth_token_static", token, { expires: 365 });
    Cookies.set("dvpn_auth_token", token, {
      path: "/",
      domain: state.domain,
      expires: 365,
    });
  } catch (e) {
    console.warn("Ошибка в setToken()");
  }
};

const deleteToken = () => {
  try {
    Cookies.remove("dvpn_auth_token_static");
    Cookies.remove("dvpn_auth_email", { path: "/", domain: state.domain });
    Cookies.remove("dvpn_auth_token", { path: "/", domain: state.domain });
  } catch (e) {
    console.warn("Ошибка в deleteToken()");
  }
};

const setEmail = (email: string) => {
  try {
    state.email = email;
    Cookies.set("dvpn_auth_email", email, {
      path: "/",
      domain: state.domain,
      expires: 365,
    });
  } catch (e) {
    console.warn("Ошибка в setEmail()");
  }
};

const getEmail = () => {
  try {
    const email = Cookies.get("dvpn_auth_email");
    state.email = email || "";
  } catch (e) {
    console.warn("Ошибка в getEmail()");
  }
};

export const authStore = {
  state,
  ...actions,
  setToken,
  setEmail,
  getEmail,
};
