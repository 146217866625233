import { PropType, defineComponent, ref } from "vue";
import { payStore } from "@/store/payStore"
import "./style.scss"

const BaseCard = defineComponent({
  name: 'BaseCard',
  props: {
    color: {
      type: String,
      default: ''
    },
    onClose: Function as PropType<() => void>
  },
  setup(props, { slots, emit }) {
    const style = ref(`background: ${props.color};`)
    const isShow = ref(false)
    setTimeout(() => { isShow.value = true }, 1)
    return () => (
      <div onClick={(e: any) => {
        if (e.target.className === "base-card base-card--show base-card--view") {
          emit("close")
        }
        payStore.state.isShowPayment = false
      }} style={style.value} class={isShow.value ? 'base-card base-card--show' : 'base-card'}>
          { slots.default!() }
      </div>
    );
  },
});

export { BaseCard };
