import { reactive, ref, computed } from "vue";
import { authStore } from "@/store/authStore";
import Cookies from 'js-cookie'

export interface IState {
  errors: Element[];
  error_popup: string|null;
}

const state = reactive<IState>({
  errors: [],
  error_popup: null
});

const actions = {
  treatment(method_name: string, error: any) {
    state.error_popup = null
    if (error?.response?.status) {
      const status = error.response.status
      const path = location.pathname

      const endpoints = ['\/auth', '\/pay\/.*']
      const toAuth = ref(true);
      endpoints.forEach(reg => {
        if (path.search(reg) !== - 1) {
          toAuth.value = false;
          return;
        }
      })
      if (status === 401 && toAuth.value) {
        Cookies.remove('dvpn_auth_token_static')
        Cookies.remove('dvpn_auth_token', { path: '/', domain: process.env.VUE_APP_MODE === '0' ? 'domain.com' : process.env.VUE_APP_MODE === '1' ? 'dl-net.ru' : 'dubki-vpn.ru' })
      }
      if (status === 429) {
        state.error_popup = "Cлишком много запросов"
      }
    }
  },
  cleanErrorPopup() {
    state.error_popup = null
  },
  setError(element: MouseEvent, messages: string[]) {
    try {
      const error = (element.target as Element).closest(".error");
      if (error) {
        state.errors.push(showError(error, messages));
      }
    } catch (e) {
      console.warn('Ошибка в setError()');
    }
  },
  deleteError() {
    try {
      state.errors.map((error: Element) => error.remove());
    } catch (e) {
      console.warn('Ошибка в deleteError()');
    }
  },
};

const showError = (element: Element, messages: string[]): Element => {
  const htmlError = ref("");
  messages.map((message: string) => {
    htmlError.value += `<li class='error__message'>${message}</li>`;
  });
  const ul = document.createElement("ul");
  ul.tabIndex = 0;
  ul.className = "error__messages error__messages--error";
  ul.innerHTML = htmlError.value;
  element.append(ul);
  ul.focus();
  state.errors.push(ul);
  return ul;
};

export const errorStore = {
  error_popup: computed(() => state.error_popup),
  ...actions,
};
