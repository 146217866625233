import { defineComponent, ref, onMounted } from "vue";
import "./style.scss";

const OpenVPNConnect = defineComponent({
  name: "OpenVPNConnect",
  setup() {
    const clientWidth = ref(document.documentElement.clientWidth);

    onMounted(() => {
      window.addEventListener("resize", () => {
        clientWidth.value = document.documentElement.clientWidth;
      });
    });
    return () => (
      <div class="connection">
        <div class={"connection-windows__title"}>
          {window.translate.openvpn_instruction_title}
        </div>
        <div>{window.translate.openvpn_instruction_step_1}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/openvpn/1.png")}
            alt=""
          />
        </div>
        <div>{window.translate.openvpn_instruction_step_2}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/openvpn/2.png")}
            alt=""
          />
        </div>
        <div>{window.translate.openvpn_instruction_step_3}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/openvpn/3.png")}
            alt=""
          />
        </div>
        <div>{window.translate.openvpn_instruction_step_4}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/openvpn/4.png")}
            alt=""
          />
        </div>
        <div>{window.translate.openvpn_instruction_step_5}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/openvpn/5.png")}
            alt=""
          />
        </div>
        <div>{window.translate.openvpn_instruction_step_6}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/openvpn/6.png")}
            alt=""
          />
        </div>
        <div>{window.translate.openvpn_instruction_step_7}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/openvpn/7.png")}
            alt=""
          />
        </div>
        <div>{window.translate.openvpn_instruction_step_8}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/openvpn/8.png")}
            alt=""
          />
        </div>
        <div>{window.translate.openvpn_instruction_step_9}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/openvpn/9.png")}
            alt=""
          />
        </div>
        <div>{window.translate.openvpn_instruction_step_10}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/openvpn/10.png")}
            alt=""
          />
        </div>
        <div>{window.translate.openvpn_instruction_step_11}</div>
        <div>
          <img
            class={
              clientWidth.value > 1200
                ? "connection-windows__image--default"
                : "connection-windows__image"
            }
            src={require("@/assets/images/connections/openvpn/11.png")}
            alt=""
          />
        </div>
      </div>
    );
  },
});

export { OpenVPNConnect };
