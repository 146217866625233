import { reactive } from 'vue';
import { savedPaymentsSourcesService, ISavedPaymentsSource } from '@/services/savedPaymentsSourcesService'
import { errorStore } from "./errorStore"

export interface IState {
  savedPaymentsSources: ISavedPaymentsSource[];
}
const state = reactive<IState>({
  savedPaymentsSources: [],
})

const actions = {
  async getSavedPaymentsSources() {
    try {
      const { data } = await savedPaymentsSourcesService.get()
      state.savedPaymentsSources = data.data
    } catch (e) {
      console.warn("Ошибка в getSavedPaymentsSources()")
      errorStore.treatment("getSavedPaymentsSources", e)
    }
  },
  async deleteSavedPaymentsSources(id: number) {
    try {
      const response = await savedPaymentsSourcesService.delete(id)
      // console.log('DEL', response)
// data: {
//   data:{
//     message: "Saved payment method was deleted."
//   }
// }
// status_code: 200
// status_message: "success"
    } catch (e) {
      console.warn("Ошибка в deleteSavedPaymentsSources()")
      errorStore.treatment("deleteSavedPaymentsSources", e)
    }
  }
}

export const savedPaymentsSourcesStore = {
  state,
  ...actions
}
