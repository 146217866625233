import { defineComponent, PropType, ref } from "vue";
import { BaseSelect } from "@/components/base/select/BaseSelect";
import "./style.scss";


export interface IVideo {
  name: string;
  poster: string;
  list: string[];
};

const BasePlayer = defineComponent({
  name: "BasePlayer",
  props: {
    list: {
      type: Array as PropType<IVideo[]>,
      required: true as true,
    },
    class: {
      type: String,
      required: true as true,
    }
  },
  setup(props) {
    const selectedIndex = ref(0);
    const type = ref();
    const player = ref();
    const options = props.list.map((video) => video.name);
    const list = ref(props.list.map((video: any) => video.list));
    const posters = props.list.map((video) => video.poster);
    const sources = ref();

    const changeVideo = (index: number) => {
      if (player.value) {
        selectedIndex.value = index;
        player.value.src = list.value[selectedIndex.value][0].split('.').slice(0, -1).join('.') + '.' + type.value;
        player.value.pause();
        player.value.load();
      }
    };
    // определение типа
    const metadata = (value: any) => {
      const temp = value.srcElement.currentSrc.split('.');
      type.value = temp[temp.length-1];
    }
    return () => list.value.length && (
      <div class={`base-player ${props.class}`}>
        <div class="base-player__nav">
          <BaseSelect onChange={(index: number) => changeVideo(index)} options={options} />
        </div>
        <video ref={player} onLoadedmetadata={(value: any) => metadata(value)} class="base-player__video" controls poster={posters[selectedIndex.value]}>
          {list.value[selectedIndex.value].map((video: string) => (
            <source src={video} type={`video/${video.split('.')[video.split('.').length - 1]}`} />
          ))}
        </video>
      </div>
    );
  },
});

export { BasePlayer };
