import { defineComponent, PropType, ref } from "vue";
import "./style.scss";


const BaseSelect = defineComponent({
  name: "BaseSelect",
  props: {
    options: {
      type: Array as PropType<any[]>,
      required: true as true,
    },
    default: {
      type: Number,
      default: 0,
    },
    onChange: Function as PropType<(index: number) => void>,
  },
  setup(props, { emit }) {
    const selectedIndex = ref(props.default);
    const selectedValue = ref(props.options[selectedIndex.value]);
    emit('change', selectedIndex.value);
    const optionsClass = ref('base-select__options--hidden');
    const chevronClass = ref('base-select__chevron--down');
    const changeClass = () => {
      if (optionsClass.value === 'base-select__options--hidden') {
        optionsClass.value = 'base-select__options--show';
        chevronClass.value = 'base-select__chevron--up';

      } else {
        optionsClass.value = 'base-select__options--hidden';
        chevronClass.value = 'base-select__chevron--down';
      }
    };
    const changeOption = (option: string, index: any) => {
      emit('change', index);
      selectedIndex.value = index;
      selectedValue.value = option;
      optionsClass.value = 'base-select__options--hidden';
      chevronClass.value = 'base-select__chevron--down';
    };
    return () => props.options.length && (
      <div class={`base-select`}>
        <div class="base-select__wrapper" onClick={changeClass}>
          <div class="base-select__value">{selectedValue.value}</div>
          <div class={`base-select__chevron ${chevronClass.value}`}></div>
        </div>
        <div class={`base-select__options ${optionsClass.value}`}>
          {props.options.map((option: any, index) => (
            <div class={`base-select__option ${(selectedIndex.value === index) ? "base-select__option--active" : ""}`} onClick={() => changeOption(option, index)}>{option}</div>
          ))}
        </div>
      </div>
    );
  },
});

export { BaseSelect };
