import { httpClient } from '@/http'

export interface ISavedPaymentsSource {
  card_type: string;
  expiry_month: string;
  expiry_year: string;
  first6: string;
  id: number;
  issuer_country: string;
  label: string;
  last4: string;
  payment_method: string;
}

export interface IDeleteRequest {
  id: number;
}
export interface IResponse {
  data: ISavedPaymentsSource[];
}

class SavedPaymentsSourcesService {
  public get() {
    return httpClient.get<IResponse>("/saved-payments-sources");
  }
  public delete(id: number) {
    return httpClient.delete(`/saved-payments-sources/${id}`);
  }
}

export const savedPaymentsSourcesService = new SavedPaymentsSourcesService();
