import { reactive } from "vue";
import { profileService } from "@/services/profileService";
import { errorStore } from "./errorStore";
import Cookies from "js-cookie";

export interface IBalance {
  bonus_money: number | null | undefined;
  email: string;
  email_confirmed: boolean | null;
  real_money: number | null | undefined;
  language: string | null;
  access_level?: number;
}
export interface IState {
  isNotBalanceFulfilledRequest: boolean;
  protection_status: boolean;
  balance: IBalance;
  isCheckBalance: boolean;
  connection: {
    password: string | null;
    username: string | null;
  };
}
const state = reactive<IState>({
  isNotBalanceFulfilledRequest: true,
  protection_status: false,
  isCheckBalance: false,
  balance: {
    bonus_money: null,
    email: "",
    language: null,
    email_confirmed: null,
    real_money: null,
    access_level: 1,
  },
  connection: {
    password: null,
    username: null,
  },
});

const actions = {
  async emailReconfirmation() {
    const response = await profileService.emailReconfirmation();
    return response;
  },
  async getConnection() {
    try {
      const { data } = await profileService.connection();
      state.connection = data.data;
      return data.data;
    } catch (e) {
      errorStore.treatment("getConnection", e);
      return e;
    }
  },
  async getBalance(executeRequest: boolean = false) {
    try {
      if (executeRequest || state.isNotBalanceFulfilledRequest) {
        state.isNotBalanceFulfilledRequest = false;
        const { data } = await profileService.balance();

        state.balance.bonus_money = data.data.bonus_money;
        state.balance.email = data.data.email;
        state.balance.language = data.data.language;
        const domain_locale = Cookies.get("dvpn_auth_locale");
        if (state.balance.language !== domain_locale) {
          const domain =
            process.env.VUE_APP_MODE === "0"
              ? "domain.com"
              : process.env.VUE_APP_MODE === "1"
              ? "dl-net.ru "
              : "dubki-vpn.ru";
          Cookies.set("dvpn_auth_locale", state.balance.language, {
            path: "/",
            domain: domain,
            expires: 365,
          });
        }

        state.balance.email_confirmed = data.data.email_confirmed;
        state.balance.real_money = data.data.real_money;

        if (data.data?.access_level) {
          state.balance.access_level = data.data.access_level;
        } else {
          state.balance.access_level = 1;
        }

        state.isCheckBalance = true;
      }
      return state.balance;
    } catch (e) {
      errorStore.treatment("getBalance", e);
    }
  },
  async online() {
    try {
      await profileService.online();
    } catch (e) {
      errorStore.treatment("online", e);
    }
  },
  async getProtectionStatus() {
    try {
      const { data } = await profileService.protection_status();
      state.protection_status = data.data.under_protection;
      return state.protection_status;
    } catch (e) {
      errorStore.treatment(" getProtectionStatus", e);
    }
  },
};

export const profileStore = {
  state,
  ...actions,
};
