import { defineComponent, onMounted, ref, computed } from "vue"
import "./style.scss"

const AppGalleryApp = defineComponent({
  name: "AppGallery",
  setup() {
    const clientWidth = ref(document.documentElement.clientWidth)
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    const images = computed(() => {
      const path = isSafari ? require.context('@/assets/images/screen-app/appgallery', false, /\.jpg$/) : require.context('@/assets/images/screen-app/appgallery', false, /\.webp$/)
      const keys = path.keys()
      if (keys.length) {
        return keys.map(path)
      } else {
        return []
      }
    })

    onMounted(() => {
      window.addEventListener('resize', () => {
        clientWidth.value = document.documentElement.clientWidth
      });
    })
    return () => (
      <div class="connection">
        <div class="screen-app__box">
          {images.value.map((image: any, index) => (<><img class="screen-app" src={image} />{index +1  < images.value.length && <div class="space-20"></div>}</>))}
        </div>
      </div>
    )
  }
})

export { AppGalleryApp }
