import { defineComponent, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { adminStore } from "@/store/adminStore";
import { BaseTable } from "@/components/base/table/BaseTable/BaseTable";
import { BaseLoading } from "@/components/base/loading";
import { BaseCard } from "@/components/base/card/BaseCard/BaseCard";
import { profileStore } from "@/store/profileStore";
import { BaseSelect } from "@/components/base/select/BaseSelect";
import "vue-select/dist/vue-select.css";
import "./style.scss";

const User = defineComponent({
  name: "User",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const pending = ref(false);
    const activeTab = ref(0);
    const changeTab = (tab: number) => {
      activeTab.value = tab;
    };
    const options = [
      "Подписки",
      "Платежи",
      "Платежные средства",
      "Активации промокодов",
      "Попытки валидации",
      "Денежные операции",
    ];

    const accessLevel = computed(() => {
      if (profileStore.state.balance.access_level) {
        if (profileStore.state.balance.access_level < 20) {
          router.push("/overview");
        }
        return profileStore.state.balance.access_level;
      } else {
        return 1;
      }
    });

    adminStore.state.user = null;
    const id = route.params.id;
    if (typeof id === "string") {
      pending.value = true;
      adminStore.user(parseInt(id)).finally(() => (pending.value = false));
    }
    const user = computed(() => {
      if (adminStore.state.user) {
        return adminStore.state.user;
      }
      return null;
    });

    const balance = computed(() => {
      if (profileStore.state.balance) {
        return profileStore.state.balance;
      }
      return null;
    });

    const convertPeriod = (row: any) => {
      if (row?.active_from && row?.active_until) {
        return `${row?.active_from} - ${row.active_until}`;
      } else {
        return "-";
      }
    };

    const convertTariff = (row: any) => {
      switch (row.tariff_plan_id) {
        case 1:
          return "Level 0 (0 RUB/MONTH)";
        case 2:
          return "Level 1 (99 RUB/MONTH)";
        case 3:
          return "Level 2 (299 RUB/MONTH)";
        case 4:
          return "Level 3 (499 RUB/MONTH)";
        case 5:
          return "Level 40 (999 RUB/MONTH)";

        case 6:
          return "Level 1 (999 RUB/YEAR)";
        case 7:
          return "Level 2 (2999 RUB/YEAR)";
        case 8:
          return "Level 3 (4999 RUB/YEAR)";
        case 9:
          return "Level 40 (9999 RUB/YEAR)";

        case 10:
          return "Level 1 (199 RUB/MONTH)";
        case 11:
          return "Level 2 (499 RUB/MONTH)";
        case 12:
          return "Level 3 (799 RUB/MONTH)";
        case 13:
          return "Level 40 (1499 RUB/MONTH)";

        case 14:
          return "Level 1 (1999 RUB/YEAR)";
        case 15:
          return "Level 2 (4999 RUB/YEAR)";
        case 16:
          return "Level 3 (7999 RUB/YEAR)";
        case 17:
          return "Level 40 (14999 RUB/YEAR)";
        default:
          return row.tariff_plan_id;
      }
    };

    const convertRenewal = (row: any) => {
      switch (row.renewal) {
        case 0:
          return "-";
        case 1:
          return "+";
        default:
          return row.renewal;
      }
    };
    const convertStatus = (row?: any) => {
      switch (row.status) {
        case "expired":
          return "Истёк";
        case "upgraded":
          return "Улучшен";
        case "downgraded":
          return "Понижен";
        case "active":
          return "Активен";
        case "canceled":
          return "Отменён";
        case "paid":
          return "Оплачен";
        case "pending":
          return "В ожидании";
        case "deleted":
          return "Удалён";
        case "created":
          return "Создан";
        case "validated":
          return "Проверен";
        case "blocked":
          return "Заблокирован";
        case "timeout":
          return "Истёк";
        default:
          return row.status;
      }
    };
    const convertType = (row?: any) => {
      switch (row.type) {
        case "add_funds":
          return "Добавление средств";
        case "pay_for_service":
          return "Оплата за услугу";
        case "plan_changing_return":
          return "Изменение плана возврата";
        default:
          return row.type;
      }
    };
    const convertPaymentMethod = (row?: any) => {
      switch (row.payment_method) {
        case "bank_card":
          return "Банковская карта";
        case "apple_pay":
          return "Apple pay";
        case "google_pay":
          return "Google pay";
        case "yookassa":
          return "Web";
        case "yookassa_mobile":
          return "Mobile";
        case "pay_from_account":
          return "Оплачено с баланса";
        default:
          return row.payment_method;
      }
    };
    const convertPurpose = (row?: any) => {
      switch (row.purpose) {
        case "confirmation":
          return "Подтвержение";
        case "auth":
          return "Авторизация";
        default:
          return row.purpose;
      }
    };
    const convertMode = (row?: any) => {
      switch (row.mode) {
        case "plan_upgrade":
          return "Обновление плана";
        default:
          return row.mode;
      }
    };
    const subscriptions_columns = [
      {
        label: "Тариф",
        field: "tariff_plan_id",
        action: convertTariff,
      },
      {
        label: "Статус",
        field: "status",
        action: convertStatus,
      },
      {
        label: "Период",
        field: "created_at",
        action: convertPeriod,
      },
    ];
    const bills_columns = [
      {
        label: "Сумма",
        field: "amount",
      },
      {
        label: "BONUS",
        field: "amount_from_bonus",
      },
      {
        label: "RUB",
        field: "amount_from_rub",
      },
      {
        label: "Создан",
        field: "created_at",
      },
      {
        label: "Оплачен",
        field: "paid_at",
      },
      {
        label: "ПС",
        field: "payment_method",
        action: convertPaymentMethod,
      },
      {
        label: "Продление",
        field: "renewal",
        action: convertRenewal,
      },
      {
        label: "Статус",
        field: "status",
        action: convertStatus,
      },
    ];
    const saved_payment_sources_columns = [
      {
        label: "Добавлена",
        field: "created_at",
      },
      {
        label: "ПС",
        field: "payment_method",
        action: convertPaymentMethod,
      },
      {
        label: "Месяц истечения срока действия",
        field: "expiry_month",
      },
      {
        label: "Год истечения срока действия",
        field: "expiry_year",
      },
      {
        label: "Начальные цифры",
        field: "first6",
      },
      {
        label: "Последние цифры",
        field: "last4",
      },
      {
        label: "Страна-эмитент",
        field: "issuer_country",
      },
      {
        label: "Статус",
        field: "status",
        action: convertStatus,
      },
    ];
    const promocodes_activations_columns = [
      {
        label: "Промокод",
        field: "promocode",
      },
      {
        label: "Режим",
        field: "mode",
        action: convertMode,
      },
    ];
    const identifier_validations_columns = [
      {
        label: "Код",
        field: "code",
      },
      {
        label: "Цель",
        field: "purpose",
        action: convertPurpose,
      },
      {
        label: "Статус",
        field: "status",
        action: convertStatus,
      },
    ];
    const operations_columns = [
      {
        label: "Знак",
        field: "sign",
      },
      {
        label: "Сумма",
        field: "amount",
      },
      {
        label: "Тип",
        field: "type",
        action: convertType,
      },
    ];
    const subscriptions_search = {
      filters: {
        tariff_plan_id: "Тариф",
        status: "Статус",
        created_at: "Дата",
      },
    };
    const bills_search = {
      filters: {
        amount: "Сумма",
        amount_from_bonus: "BONUS",
        amount_from_rub: "RUB",
        created_at: "Создано",
        paid_at: "Оплачено",
        status: "Статус",
      },
    };
    const saved_payment_sources_search = {
      filters: {
        created_at: "Дата",
        first6: "Начальные цифры",
        last4: "Последние цифры",
        expiry_month: "Месяц истечения срока действия",
        expiry_year: "Год истечения срока действия",
        status: "Статус",
      },
    };
    const promocodes_activations_search = {
      filters: {
        promocode: "Промокод",
        mode: "Режим",
      },
    };
    const identifier_validations_search = {
      filters: {
        code: "Код",
        purpose: "Цель",
        status: "Статус",
      },
    };
    const operations_search = {
      filters: {
        sign: "Знак",
        amount: "Сумма",
        type: "Тип",
      },
    };
    const copy = (e: MouseEvent, value: string) => {
      const element = e.target as HTMLInputElement;
      if (element) {
        if (element.dataset.copy === "0") {
          const el = document.createElement("textarea");
          el.value = value;
          el.setAttribute("readonly", "");
          el.style.position = "absolute";
          el.style.left = "-9999px";
          document.body.appendChild(el);
          el.select();
          document.execCommand("copy");
          document.body.removeChild(el);
          element.dataset.copy = "1";
          element.innerHTML = "скопировано";
          element.style.cssText = "color: green !important";
          setTimeout(() => {
            element.innerHTML = "скопировать";
            element.style.cssText = "color: #fff !important";
            element.dataset.copy = "0";
          }, 2000);
        }
      }
    };
    return () =>
      pending.value ? (
        <BaseLoading />
      ) : accessLevel.value >= 20 && user.value ? (
        <div class="user-content">
          <div class="user-content__box">
            <div class="user-content__data">
              <div>
                <span>E-mail:</span> {user.value.email}
              </div>
              <div>
                <span>Баланс:</span>{" "}
                {user.value?.real_money ? user.value.real_money + " ₽" : "0 ₽"}
              </div>
              <div>
                <span>Бонусный баланс:</span>{" "}
                {user.value?.bonus_money
                  ? user.value.bonus_money + " ₽"
                  : "0 ₽"}
              </div>
              <div>
                <span>IP-адрес регистрации:</span> {user.value.created_from}
              </div>
              <div>
                <span>Логин для подключения:</span>{" "}
                <span
                  class="user-content__button"
                  data-copy="0"
                  onClick={(e) => copy(e, user.value.username)}
                >
                  скопировать
                </span>
              </div>
              <div>
                <span>Пароль для подключения:</span>{" "}
                <span
                  class="user-content__button"
                  data-copy="0"
                  onClick={(e) => copy(e, user.value.password)}
                >
                  скопировать
                </span>
              </div>
            </div>
            <div class="user-content__switch">
              {options.map((option, index) => {
                return (
                  <div
                    onClick={() => changeTab(index)}
                    class={
                      activeTab.value === index
                        ? "user-content__option user-content__option--active"
                        : "user-content__option"
                    }
                  >
                    {option}
                  </div>
                );
              })}
            </div>
          </div>
          <BaseSelect
            onChange={(index: number) => changeTab(index)}
            class="user-content__select"
            options={options}
          />
          {activeTab.value === 0 && (
            <div>
              <BaseCard
                class="v-card--scroll"
                style="display: flex; flex-direction: column;max-width: 1010px;color: #fff; margin-right: 0;width: 100%;min-height: 234px;"
              >
                <BaseTable
                  searchPlaceholder="Поиск..."
                  notData="Нет данных"
                  search={subscriptions_search}
                  simpleSearch={true}
                  columns={subscriptions_columns}
                  rows={user.value.subscriptions}
                />
              </BaseCard>
            </div>
          )}
          {activeTab.value === 1 && (
            <div>
              <BaseCard
                class="v-card--scroll"
                style="display: flex; flex-direction: column;max-width: 1010px;color: #fff; margin-right: 0;width: 100%;min-height: 234px;"
              >
                <BaseTable
                  search={bills_search}
                  searchPlaceholder="Поиск..."
                  notData="Нет данных"
                  simpleSearch={true}
                  columns={bills_columns}
                  rows={user.value.bills}
                />
              </BaseCard>
            </div>
          )}
          {activeTab.value === 2 && (
            <div>
              <BaseCard
                class="v-card--scroll"
                style="display: flex; flex-direction: column;max-width: 1010px;color: #fff; margin-right: 0;width: 100%;min-height: 234px;"
              >
                <BaseTable
                  searchPlaceholder="Поиск..."
                  notData="Нет данных"
                  search={saved_payment_sources_search}
                  simpleSearch={true}
                  columns={saved_payment_sources_columns}
                  rows={user.value.saved_payment_sources}
                />
              </BaseCard>
            </div>
          )}
          {activeTab.value === 3 && (
            <div>
              <BaseCard
                class="v-card--scroll"
                style="display: flex; flex-direction: column;max-width: 1010px;color: #fff; margin-right: 0;width: 100%;min-height: 234px;"
              >
                <BaseTable
                  searchPlaceholder="Поиск..."
                  notData="Нет данных"
                  search={promocodes_activations_search}
                  simpleSearch={true}
                  columns={promocodes_activations_columns}
                  rows={user.value.promocodes_activations}
                />
              </BaseCard>
            </div>
          )}
          {activeTab.value === 4 && (
            <div>
              <BaseCard
                class="v-card--scroll"
                style="display: flex; flex-direction: column;max-width: 1010px;color: #fff; margin-right: 0;width: 100%;min-height: 234px;"
              >
                <BaseTable
                  searchPlaceholder="Поиск..."
                  notData="Нет данных"
                  search={identifier_validations_search}
                  simpleSearch={true}
                  columns={identifier_validations_columns}
                  rows={user.value.identifier_validations}
                />
              </BaseCard>
            </div>
          )}
          {activeTab.value === 5 && (
            <div>
              <BaseCard
                class="v-card--scroll"
                style="display: flex; flex-direction: column;max-width: 1010px;color: #fff; margin-right: 0;width: 100%;min-height: 234px;"
              >
                <BaseTable
                  searchPlaceholder="Поиск..."
                  notData="Нет данных"
                  search={operations_search}
                  simpleSearch={true}
                  columns={operations_columns}
                  rows={user.value.operations}
                />
              </BaseCard>
            </div>
          )}
        </div>
      ) : (
        <div class="user-content user-content--not-data">Не найден</div>
      );
  },
});

export { User };
