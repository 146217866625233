import { httpClient } from '@/http'

interface IMeta {
  current_page: number;
  last_page: number;
  per_page: number;
  total: number;
}
interface IParams {
  filter?: string;
  value?: string;
}

interface IDataResponse {
  data: any[];
  meta: IMeta;
}

class AdminService {
  public users(activePage: number, params: IParams) {
    let filter = "";
    let value = "";
    
    if (params.filter) {
      filter = params.filter
    }
    if (params.value) {
      value = params.value
    }

    const url = { value: `/admin/user/all?page=${activePage}` };
    if (filter && !value) {
      url.value += `&filter[${filter}]=`;
    }
    else if (filter && value) {
      url.value += `&filter[${filter}]=${value}`;
    }

    return httpClient.get<IDataResponse>(url.value)
  }
  
  public user(id: number) {
    return httpClient.get(`/admin/user/${id}`)
  }
}

export const adminService = new AdminService()
