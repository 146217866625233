import { defineComponent, ref, computed } from "vue";
import { promocodeStore } from "@/store/promocodeStore";
import "./style.scss";

const GetPromocodes = defineComponent({
  name: "GetPromocodes",
  setup() {
    const list = computed(() => promocodeStore.state.list);
    promocodeStore.listPersonalCode();
    const handle = () => {
      promocodeStore.createPersonalCode();
    };
    const copyBuff = (text: string) => {
      const el = document.createElement("textarea");
      el.value = text;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    };
    return () => (
      <div class="get-promocodes">
        <label>{window.translate.list_promo_codes}</label>
        {list.value.length ? (
          list.value.map((item) => (
            <div
              class="get-promocodes__value"
              data-copy-label={window.translate.click_for_copying}
              onClick={() => copyBuff(item.promocode)}
            >
              {item.promocode}
            </div>
          ))
        ) : (
          <div class="get-promocodes__not-value">{window.translate.no_promo_codes}</div>
        )}
        {list.value.length && (
          <div class="get-promocodes__value-help">
            ({window.translate.promo_code_copy})
          </div>
        )}
        <button onClick={handle}>{window.translate.get_promo_code}</button>
      </div>
    );
  },
});

export { GetPromocodes };
