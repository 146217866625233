import { defineComponent, computed } from 'vue'
import { GetPromocodes } from '@/components/getPromocodeCard/GetPromocodeCard'
import { promocodeStore } from "@/store/promocodeStore"
import './style.scss'

const TheBonuses = defineComponent({
  name: 'TheBonuses',
  setup() {
    const activation = computed(() => promocodeStore.state.promocode_activations)
    const earned_bonus_coins = computed(() => promocodeStore.state.earned_bonus_coins)
    const last_activation = computed(() => {
      if (promocodeStore.state.last_activation) {
        return promocodeStore.state.last_activation
      } else {
        return '-'
      }
    })
    promocodeStore.statsPersonalCode()

    return () => (
      <div class="bonuses">
        {(promocodeStore.state.error && !promocodeStore.state.isPromocode) && <div class="bonuses__error">{promocodeStore.state.error}</div>}
        <GetPromocodes />
        <h2>{window.translate.statistics}</h2>
        <ul>
          <li>{window.translate.last_activation} <strong>{ last_activation.value }</strong></li>
          <li>{window.translate.total_activations} <strong>{ activation.value }</strong></li>
          <li>{window.translate.bonuses_accrued} <strong>{ earned_bonus_coins.value }</strong></li>
        </ul>
        <h2>{window.translate.terms}</h2>
        <p v-html={window.translate.ref_program_terms_description}></p>
      </div>
    )
  }
})

export { TheBonuses }
