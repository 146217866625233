import { defineComponent, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { BaseCard } from "@/components/base/card/BaseCard/BaseCard";
import { BaseTable } from "@/components/base/table/BaseTable/BaseTable";
import { businessStore } from "@/store/businessStore";
import { profileStore } from "@/store/profileStore";
import "./style.scss";

export const CompanyList = defineComponent({
  name: "page-company-list",
  setup() {
    const router = useRouter();
    const loading = ref(true);
    const columns = ref([
      {
        label: window.translate.id,
        field: "id",
        style: "width: 50px",
        counter: true,
      },
      {
        label: "Название",
        field: "name",
      },
      {
        label: "Имя домена",
        field: "company_domain_name",
      },
    ]);

    businessStore.clientList().finally(() => {
      loading.value = false;
    });

    const accessLevel = computed(() => {
      if (profileStore.state.balance.access_level) {
        return profileStore.state.balance.access_level;
      } else {
        return 1;
      }
    });

    const data = computed(() => {
      if (businessStore.state.clients.length) {
        return businessStore.state.clients;
      }
      return [];
    });

    const toCreateCompany = () => {
      router.push({ name: "company-create" });
    };

    const toUpdate = (row: any) => {
      if (accessLevel.value >= 90) {
        router.push({
          name: "company-update",
          params: {
            companyId: row.id,
          },
        });
      }
    };

    return () => (
      <>
        {accessLevel.value >= 90 && (
          <div class="company__controls">
            <button onClick={toCreateCompany} class="company__create">
              Добавить компанию
            </button>
          </div>
        )}
        <BaseCard
          class="v-card--scroll"
          style="max-width: 1010px;color: #fff; margin-right: 0;width: 100%;min-height: 234px;"
        >
          <BaseTable
            columns={columns.value}
            rows={data.value}
            loading={loading.value}
            onRowClick={(row) => toUpdate(row)}
          />
        </BaseCard>
      </>
    );
  },
});
