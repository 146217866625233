import { defineComponent, ref } from "vue";
import { savedPaymentsSourcesStore } from "@/store/savedPaymentsSourcesStore";
import "./style.scss";

const BaseCardCredit = defineComponent({
  name: "BaseCardCredit",
  props: {
    typeCard: {
      type: String,
      required: true as true,
    },
    number: {
      type: String,
      required: true as true,
    },
    name: {
      type: String,
      required: true as true,
    },
    date: {
      type: String,
      required: true as true,
    },
    id: {
      type: Number,
      required: true as true,
    },
  },
  setup(props) {
    const cardNumber: string = props.number;
    const isShow = ref(false);
    setTimeout(() => {
      isShow.value = true;
    }, 1);
    const isShowCard = ref(true);
    const handleDelete = () => {
      savedPaymentsSourcesStore
        .deleteSavedPaymentsSources(props.id)
        .then(() => {
          isShowCard.value = false;
          savedPaymentsSourcesStore.getSavedPaymentsSources();
        });
    };
    const front = ref();
    const back = ref();
    const isFlip = ref(true);
    const flipCard = () => {
      if (isFlip.value) {
        front.value.style.transform = "rotateY(180deg)";
        back.value.style.transform = "rotateY(0deg)";
        isFlip.value = false;
      } else {
        back.value.style.transform = "rotateY(180deg)";
        front.value.style.transform = "rotateY(0deg)";
        isFlip.value = true;
      }
    };
    const content = () => {
      if (isShowCard.value) {
        return (
          <div
            class={
              isShow.value ? "credit-card credit-card--show" : "credit-card"
            }
          >
            <div onClick={flipCard} ref={front} class={"credit-card__front"}>
              <div class={"credit-card__chip"}> </div>
              <div class={"credit-card__number"}>{cardNumber}</div>
              <div class={"credit-card__info"}>{props.name}</div>
              <div class={"credit-card__date"}>{props.date}</div>
              <div class={"credit-card__payment"}>{props.typeCard}</div>
            </div>
            <div onClick={flipCard} ref={back} class={"credit-card__back"}>
              <div class={"credit-card__magnet"}></div>
              <div
                onClick={handleDelete}
                class={"credit-card__delete cursor-pointer"}
              >
                {window.translate.delete_card}
              </div>
            </div>
          </div>
        );
      }
      return null;
    };
    return content;
  },
});

export { BaseCardCredit };
