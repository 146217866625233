import { defineComponent } from "vue"
import { HistoryTariffCard } from "@/components/historyTariffCard/HistoryTariffCard"

const Tariff = defineComponent({
  name: "Tariff",
  setup() {
    return () => (
      <div class={"tariff"}>
        <HistoryTariffCard />
      </div>
    )
  },
})

export { Tariff };
