import { reactive } from 'vue'
import { validationsService } from '@/services/validationsService'

export interface IState {
  session_id: string|null;
  code: string|null;
}
const state = reactive<IState>({
    session_id: null,
    code: null
})

const actions = {
  async identifiers() {
    if (!!state.session_id && !!state.code) {
        const response = await validationsService.identifiers(state.session_id, state.code)
        return response
    }
  }
}

export const validationsStore = {
  state,
  ...actions,
};

