import { defineComponent } from "vue";
import { authStore } from "@/store/authStore";
import Cookies from "js-cookie";
import { Locales } from "@/components/locales";
import "./style.scss";

const TheHeader = defineComponent({
  name: "TheHeader",
  setup() {
    const email = Cookies.get("dvpn_auth_email");
    const logout = () => authStore.logoutAuth();
    return () => (
      <div class="header">
        <div class="header__email">{email}</div>
        <Locales class="header__locales" />
        <div onClick={logout} class="header__logout">
          {window.translate.logout}
        </div>
      </div>
    );
  },
});

export { TheHeader };
