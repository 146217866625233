import { defineComponent, ref } from 'vue'
import { TheContent } from '@/components/content/TheContent'
import { TheSidebar } from '@/components/sidebar/TheSidebar'
import { sidebarStore } from "@/store/sidebarStore"

const DashBoard = defineComponent({
  name: 'DashBoard',
  setup() {
    const isChangeComponent = ref(false)
    setTimeout(() => { isChangeComponent.value = true }, 1)
    return () => (
      <div class={'wrapper'}>
        <div class={isChangeComponent.value ? 'dashboard change' : 'dashboard'}>
          <div class={'row'}>
            <TheSidebar />
            <TheContent />
            <div onClick={() => sidebarStore.state.active = false} class={sidebarStore.state.active ? "invisible-barier invisible-barier--show" : "invisible-barier" }></div>
          </div>
        </div>
      </div>
    )
  }
})

export { DashBoard }
