import { defineComponent } from "vue";
import { BaseCardCredit } from "@/components/base/card/BaseCardCredit/BaseCardCredit";
import { BaseCardVirtual } from "@/components/base/card/BaseCardVirtual/BaseCardVirtual";
import { savedPaymentsSourcesStore } from "@/store/savedPaymentsSourcesStore";
import { ISavedPaymentsSource } from "@/services/savedPaymentsSourcesService";
import "./style.scss";

const TheCards = defineComponent({
  name: "TheCards",
  setup() {
    savedPaymentsSourcesStore.getSavedPaymentsSources();
    return () => (
      <div>
        {savedPaymentsSourcesStore.state.savedPaymentsSources.length > 0 ? (
          <div style={"display: flex;flex-wrap: wrap;"}>
            {savedPaymentsSourcesStore.state.savedPaymentsSources.map(
              (savedPaymentsSource: ISavedPaymentsSource) => {
                const id = savedPaymentsSource.id;
                const paymentMethod= savedPaymentsSource.payment_method;
                if (['apple_pay', 'google_pay'].includes(paymentMethod)) {
                  return (
                    <BaseCardVirtual
                      paymentMethod={paymentMethod.replace(/_/ig, ' ')}
                      id={id}
                    />
                  )
                } else {
                  const number = `${savedPaymentsSource.first6.slice(
                    0,
                    4
                  )} ${savedPaymentsSource.first6.slice(-2)}** **** ${
                    savedPaymentsSource.last4
                  }`;
                  const date = `${
                    savedPaymentsSource.expiry_month
                  }/${savedPaymentsSource.expiry_year.slice(-2)}`;
                  const name = savedPaymentsSource.label;
                  const typeCard = savedPaymentsSource.card_type;
                  return (
                    <BaseCardCredit
                      id={id}
                      typeCard={typeCard}
                      number={number}
                      name={name}
                      date={date}
                    />
                  );
                }
              }
            )}
          </div>
        ) : (
          <div class="cards--none">
            <div class="cards--none-text">{window.translate.no_cards}</div>
          </div>
        )}
      </div>
    );
  },
});

export { TheCards };
